import Link from "next/link";
import { signOut } from "@stores/slices/userSlice";
import {
  MY_PROFILE_ROUTE_PATH,
  ORDER_HISTORY_PATH,
  WISHLIST_ROUTE_PATH,
  MY_COUPON_PATH,
} from "@const/route-paths.const";
import {
  isWebview,
  clickAccountButtonNative,
  logoutNative,
  clickWishListButtonNative,
} from "@utils/webview.util";
import { useAppDispatch } from "@stores/store";

type Props = {
  suggestClose: () => void | undefined;
};

const SuggestAccountMenu = ({ suggestClose }: Props) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <div className="account-menu">
        <table className="table mb-0">
          <tbody>
            <tr>
              <td>
                <Link href={MY_PROFILE_ROUTE_PATH}>
                  <a
                    className="nav-link"
                    onClick={(e) => {
                      if (isWebview()) {
                        e.preventDefault();
                        clickAccountButtonNative();
                      }
                    }}
                  >
                    <i className="icon icon-profile mx-2" />
                    บัญชีของฉัน
                  </a>
                </Link>
              </td>
            </tr>
            <tr>
              <td>
                <Link href={ORDER_HISTORY_PATH}>
                  <a className="nav-link">
                    <i className="icon icon-orderlist mx-2" />
                    รายการคำสั่งซื้อ
                  </a>
                </Link>
              </td>
            </tr>
            <tr>
              <td>
                <Link href={WISHLIST_ROUTE_PATH}>
                  <a
                    className="nav-link"
                    onClick={(e) => {
                      if (isWebview()) {
                        e.preventDefault();
                        clickWishListButtonNative();
                      }
                    }}
                  >
                    <i className="icon icon-wishlist mx-2" />
                    รายการสินค้าโปรด
                  </a>
                </Link>
              </td>
            </tr>
            <tr>
              <td>
                <Link href={MY_COUPON_PATH}>
                  <a className="nav-link">
                    <i className="icon icon-coupon mx-2" />
                      คูปองของฉัน
                  </a>
                </Link>
              </td>
            </tr>
            <tr>
              <td>
                <a
                  className="nav-link text-center text-danger"
                  onClick={() => {
                    dispatch(signOut());
                    suggestClose();
                    if (isWebview()) {
                      logoutNative();
                    }
                  }}
                >
                  ออกจากระบบ
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SuggestAccountMenu;
