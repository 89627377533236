import { useState, useEffect } from "react";
import { useHomeCategories } from "@api/home-category/home-category";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import Accordion from "react-bootstrap/Accordion";
import Link from "next/link";
import Loader from "@components/atoms/Loader";

type Props = {
  handleCloseOffcanvas: () => void;
};

const TabsNavigate = ({ handleCloseOffcanvas }: Props) => {
  const [categoryTitle, setCategoryTitle] = useState("");
  const [categoryPath, setCategoryPath] = useState("");

  // get Categories
  const { data: dataHomeCategories, isLoading: isLoadingHomeCategories } =
    useHomeCategories();

  let firstLoadData = dataHomeCategories.menu_vertical?.find(
    (data) => data.category_id > 0
  );

  useEffect(() => {
    if (firstLoadData) {
      setCategoryTitle(firstLoadData.label);
      setCategoryPath(firstLoadData.urlpath);
    }
  }, [firstLoadData]);

  return (
    <>
      {isLoadingHomeCategories ? (
        <Loader />
      ) : (
        <Tab.Container id="left-tabs-example" defaultActiveKey="3">
          <Row className="g-0">
            {/* Main Category */}
            <Col xs={3} className="tabs-main-category">
              <Nav variant="pills" className="flex-column">
                {dataHomeCategories &&
                  dataHomeCategories.menu_vertical?.map((data, id) => {
                    return (
                      <Nav.Item key={id} className="text-center mb-1">
                        <Nav.Link
                          eventKey={data.category_id}
                          onClick={() => {
                            setCategoryTitle(data.label);
                            setCategoryPath(data.urlpath);
                          }}
                          className="px-0 pt-2"
                        >
                          <img src={data.icon_menu} alt={data.label} />
                          <p className="text-body">{data.label}</p>
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
              </Nav>
            </Col>

            {/* Sub Category */}
            <Col xs={9}>
              <Tab.Content className="mb-3">
                {isLoadingHomeCategories ? (
                  <Loader />
                ) : (
                  <>
                    {dataHomeCategories.menu_vertical?.map((dataL1, idL1) => {
                      return (
                        <Tab.Pane key={idL1} eventKey={dataL1.category_id}>
                          <Accordion defaultActiveKey="0">
                            {dataL1.sub_category_l2.map((dataL2, idL2) => {
                              return (
                                <Accordion.Item
                                  eventKey={String(idL2)}
                                  key={idL2}
                                  className="border-0 border-bottom"
                                >
                                  <Accordion.Header>
                                    <Link href={`/${dataL2.urlpath}`}>
                                      <a onClick={handleCloseOffcanvas}>
                                        {dataL2.label}
                                      </a>
                                    </Link>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {dataL2.sub_category_l3.map(
                                      (dataL3, idL3) => {
                                        return (
                                          <Link
                                            key={idL3}
                                            href={`/${dataL3.urlpath}`}
                                          >
                                            <a onClick={handleCloseOffcanvas}>
                                              <p>{dataL3.label}</p>
                                            </a>
                                          </Link>
                                        );
                                      }
                                    )}
                                  </Accordion.Body>
                                </Accordion.Item>
                              );
                            })}
                          </Accordion>
                        </Tab.Pane>
                      );
                    })}
                  </>
                )}
              </Tab.Content>
              <div className="ps-3">
                <Link href={categoryPath}>
                  <a onClick={handleCloseOffcanvas}>
                    <small className="fs-6">{categoryTitle}ทั้งหมด</small>
                  </a>
                </Link>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      )}
    </>
  );
};

export default TabsNavigate;
