import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from "react";
import { FLASHSALE_CATEGORY_ID } from "@const/flashsale-category.const";

interface FlashsaleContextType {
  idFlashsale: string;
  setIdFlashsale: React.Dispatch<React.SetStateAction<string>>;
}

export const FlashsaleContext = createContext<FlashsaleContextType | undefined>(
  undefined
);

interface Props {
  children: ReactNode;
}

export const useCountdown = () => useContext(FlashsaleContext)!;

export const ContextProvider = ({ children }: Props) => {
  const [idFlashsale, setIdFlashsale] = useState(FLASHSALE_CATEGORY_ID);

  const value: FlashsaleContextType = {
    idFlashsale,
    setIdFlashsale,
  };

  return (
    <FlashsaleContext.Provider value={value}>
      {children}
    </FlashsaleContext.Provider>
  );
};
