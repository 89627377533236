import {
  CreatePasswordDto,
  ForgetPasswordDto,
  ProfileInputDto,
  SubscriptionInputDto,
  SubscriberDto,
} from "@interfaces/dto/user-data.dto";
import { API_MESSAGE } from "@const/api-messages.const";
import { ApiResponse } from "@interfaces/dto/swr.dto";
import { api as userApi } from "@api/_base_user";
import { poster } from "@api/_base";

export const updateProfile = async (
  inputs: ProfileInputDto
): Promise<ApiResponse> => {
  try {
    await userApi.put(`/customers/me`, inputs);
    return { isOk: true, message: "อัพเดตโปรไฟล์สำเร็จ" };
  } catch (error: any) {
    const errorMessage: string =
      error.response.data.message ?? API_MESSAGE.INTERNAL_ERROR;
    return { isOk: false, message: errorMessage };
  }
};

export const updateSubscribe = async (
  inputs: SubscriptionInputDto
): Promise<ApiResponse> => {
  try {
    await userApi.put(`/customers/me`, inputs);
    return { isOk: true, message: "อัพเดตโปรไฟล์สำเร็จ" };
  } catch (error: any) {
    const errorMessage: string =
      error.response.data.message ?? API_MESSAGE.INTERNAL_ERROR;
    return { isOk: false, message: errorMessage };
  }
};

export const sendForgetPasswordEmail = async (
  reqBody: ForgetPasswordDto
): Promise<ApiResponse> => {
  try {
    await userApi.put(`/customers/password`, reqBody);
    return { isOk: true, message: "ส่งคำขอรีเซ็ตรหัสผ่านสำเร็จ" };
  } catch (error: any) {
    const errorMessage: string =
      error.response.data.message ?? API_MESSAGE.INTERNAL_ERROR;
    return { isOk: false, message: errorMessage };
  }
};

export const createNewPassword = async (
  body: CreatePasswordDto
): Promise<ApiResponse> => {
  try {
    const response = await userApi.post(`/customers/resetPassword`, body);
    return {
      isOk: true,
      message: "เปลี่ยนรหัสผ่านสำเร็จ กรุณาล็อคอินใหม่อีกครั้ง",
    };
  } catch (error: any) {
    const errorMessage: string =
      error.response.data.message ?? API_MESSAGE.INTERNAL_ERROR;
    return { isOk: false, message: errorMessage };
  }
};

export const addSubscriber = async (
  inputEmail: SubscriberDto
): Promise<ApiResponse> => {
  try {
    //   console.log("api data:", inputReview);
    const { data } = await poster(`/nextapi/subscriber`, inputEmail);
    return { isOk: true, message: "รับสมัครข่าวสารเสร็จสิ้น", data };
  } catch (error: any) {
    const errorMessage: string =
      error.response.data.message ?? API_MESSAGE.INTERNAL_ERROR;
    return { isOk: false, message: errorMessage };
  }
};
