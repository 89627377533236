import { RangeValues } from "@interfaces/components/range-slide.interface";

export const numberToMoneyString = (input: number, decimal: number) =>
  input?.toLocaleString("th-TH", { minimumFractionDigits: decimal });

export const extractRangeValuesFromString = (
  input: string | undefined
): RangeValues | undefined => {
  if (!input) return undefined;
  const values: number[] = input.split("-").map((value) => Number(value));
  return values.length === 2 ? { start: values[0], end: values[1] } : undefined;
};
