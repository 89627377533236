export const composeQueryParams = (params: (string | undefined)[]): string => {
  return params.filter((param) => param !== undefined).join("&");
};

export const composeArrayToQueryParam = (
  params: (string | undefined)[]
): string => {
  return params.filter((param) => param !== undefined).join("/");
};

export const removeHtmlLegacyFromUrl = (input: string) => {
  return input.replace(".html", "");
};
