import "@styles/app.css";
import "@styles/custom.css";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "moment/locale/th";
import Layout from "@components/layouts/Layout";
import type { AppProps } from "next/app";
import { store } from "@stores/store";
import { Provider } from "react-redux";
import Head from "next/head";
import Router, { useRouter } from "next/router";
import { NextAdapter } from "next-query-params";
import { QueryParamProvider } from "use-query-params";
import { getSession, forceLogin } from "@stores/slices/userSlice";
import React from "react";
import { ToastContainer } from "react-toastify";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import {
  CART_CALLBACK_ROUTE_PATH,
  CART_CALLBACK_CC_ROUTE_PATH,
} from "@const/route-paths.const";
import { isWebview, receiveTokenLoginNative } from "@utils/webview.util";
// SEO
import { DefaultSeo } from "next-seo";
import { SEO } from "../../next-seo.config";
// GTM
import { initializeGTM } from "@utils/gtm.util";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import SmartBanner from "@components/features/smart-banner/SmartBanner";
import {
  BANNER_APP_ANDROID_URL,
  BANNER_APP_IOS_URL,
} from "@const/smart-banner.const";

const ROUTES_WITHOUT_LAYOUT: string[] = [
  CART_CALLBACK_ROUTE_PATH,
  CART_CALLBACK_CC_ROUTE_PATH,
];

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const MyApp = ({
  Component,
  pageProps,
}: AppProps<{
  session: Session;
}>) => {
  const router = useRouter();

  // init GTM
  React.useEffect(() => {
    initializeGTM();
  }, []);

  // update session & set token
  React.useEffect(() => {
    store.dispatch(getSession());
  }, []);

  // auto login from native app
  const handleForceLoginFromNative = async (token: string) => {
    if (isWebview()) {
      const resp = await store.dispatch(forceLogin(token));
      const { payload } = await store.dispatch(getSession());
      if (payload.token) {
        receiveTokenLoginNative(payload.token);
      }
    }
  };

  React.useEffect(() => {
    window.autoLogin = (token: string) => {
      handleForceLoginFromNative(token);
    };
  }, []);

  return (
    <>
      <SmartBanner
        appStoreUrl={BANNER_APP_IOS_URL}
        playStoreUrl={BANNER_APP_ANDROID_URL}
      />
      <Provider store={store}>
        <SessionProvider session={pageProps.session}>
          <ToastContainer />
          <QueryParamProvider adapter={NextAdapter}>
            <Head>
              <meta
                name="viewport"
                id="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
              />
            </Head>
            {ROUTES_WITHOUT_LAYOUT.includes(router.pathname) ? (
              <Component {...pageProps} />
            ) : (
              <Layout>
                <DefaultSeo {...SEO} />
                <Component {...pageProps} />
              </Layout>
            )}
          </QueryParamProvider>
        </SessionProvider>
      </Provider>
    </>
  );
};

export default MyApp;
