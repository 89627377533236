import { SEO_SITEURL } from "@const/seo.const";

const additionalMetaTags = [
    {
        name: "keywords",
        content: "เครื่องใช้ไฟฟ้า, เครื่องใช้ไฟฟ้าราคาคุ้มค่า, ของใช้ในบ้าน, สินค้าตกแต่งบ้าน, alectric, altec, namiko, sce, cocogu, fennix, xiaomi"
    },
    {
        name: "format-detection",
        content: "telephone=no"
    },
];
export const SEO = {
    title: "topvalue.com คัดสรร ความคุ้มค่า...เพื่อคุณ",
    titleTemplate: "%s",
    defaultTitle: "topvalue.com คัดสรร ความคุ้มค่า...เพื่อคุณ",
    description: "คัดสรร นวัตกรรมเครื่องใช้ไฟฟ้า และสิ่งอำนวยความสะดวกที่สวยงาม เพื่อคุณ",
    additionalMetaTags: additionalMetaTags,
    openGraph: {
        title: "topvalue.com คัดสรร ความคุ้มค่า...เพื่อคุณ",
        description: "คัดสรร นวัตกรรมเครื่องใช้ไฟฟ้า และสิ่งอำนวยความสะดวกที่สวยงาม เพื่อคุณ",
        type: "website",
        locale: "th_TH",
        url: SEO_SITEURL,
        site_name: "Topvalue ช้อปสินค้าแท้ออนไลน์ เครื่องใช้ไฟฟ้า ส่งฟรีทั่วไทย",
        images: [
            {
                url: `${SEO_SITEURL}/assets/images/topvalue-logo-seo.jpg`,
            },
        ],
    },
    twitter: {
        handle: "@topvalueTH",
        site: "@topvalueTH",
        cardType: "summary",
    }
}