import Header from "@components/layouts/Header";
import Footer from "@components/layouts/Footer";
import { ReactNode, useEffect, useState } from "react";
import { useHomeCategories } from "@api/home-category/home-category";
import BottomNavigate from "@components/features/navigation/BottomNavigate";
import { useRouter } from "next/router";
import { CART_ROUTE_PATH, PRODUCT_VIEW_PATH } from "@const/route-paths.const";
import { isWebview } from "@utils/webview.util";
import { M_TERMS_ROUTE_PATH } from "@const/route-paths.const";
import { ContextProvider } from "../features/flashsale/Context";

interface Props {
  children: ReactNode;
}

const Layout = ({ children }: Props) => {
  const topMenusResponse = useHomeCategories();
  const router = useRouter();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isWebview()) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, []);

  return (
    <>
      {router.pathname === M_TERMS_ROUTE_PATH ? (
        <>{children}</>
      ) : (
        <>
          <Header topMenusResponse={topMenusResponse} />
          <ContextProvider>{children}</ContextProvider>
          <Footer />
          {router.pathname !== PRODUCT_VIEW_PATH &&
          router.pathname !== CART_ROUTE_PATH ? (
            <div className={`${show ? "d-block" : "d-none"}`}>
              <BottomNavigate />
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default Layout;
