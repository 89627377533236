import { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { CART_ROUTE_PATH } from "@const/route-paths.const";
import NoitemCart from "./NoitemCart";
import { isWebview, clickAccountButtonNative } from "@utils/webview.util";

const MinicartBlank = () => {
  // Modal
  useEffect(() => {
    document.addEventListener("click", handleClickoutside, true);
  });

  const [showMinicart, setShowMinicart] = useState(false);
  const cartRef = useRef<HTMLDivElement>(null);
  const handleClickoutside = (e: any) => {
    if (!cartRef.current?.contains(e.target)) {
      setShowMinicart(false);
    }
    if (e.target.className === "btn btn-red2") {
      setShowMinicart(false);
    }
  };

  return (
    <>
      <div className="d-block d-lg-none">
        <Link href={CART_ROUTE_PATH}>
          <a
            className="d-flex"
            onClick={(e) => {
              if (isWebview()) {
                e.preventDefault();
                clickAccountButtonNative();
              }
            }}
          >
            <i className="icon icon-cart" />
          </a>
        </Link>
      </div>
      <div className="d-none d-lg-block">
        <div className="minicart-wrapper" ref={cartRef}>
          <a className="d-flex" onClick={() => setShowMinicart(!showMinicart)}>
            <i className="icon icon-cart" />
            <p className="d-none d-md-flex flex-column">
              <span>รถเข็นสินค้า</span>
              <span className="fw-bold">0 ชิ้น</span>
            </p>
          </a>

          {showMinicart && (
            <div className="minicart-content">
              <table className="table mt-4 mb-0">
                <tbody>
                  <NoitemCart />
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={3} className="text-center">
                      <div className="d-grid gap-2">
                        <Link href={CART_ROUTE_PATH}>
                          <a
                            className="btn btn-red2"
                            onClick={(e) => {
                              if (isWebview()) {
                                e.preventDefault();
                                clickAccountButtonNative();
                              }
                            }}
                          >
                            ไปที่ตะกร้า
                          </a>
                        </Link>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MinicartBlank;
