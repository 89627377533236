import { CartX } from "react-bootstrap-icons";

const NoitemCart = () => {
  return (
    <tr>
      <td colSpan={3} className="text-center no-item-incart">
        <CartX size={60} className="mb-4" />
        <h4>ไม่มีสินค้าในตะกร้า</h4>
        <p>คุณไม่มีสินค้าในตะกร้า โปรดเลือกหยิบสินค้าที่ต้องการซื้อลงตะกร้า</p>
      </td>
    </tr>
  );
};

export default NoitemCart;
