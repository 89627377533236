import Link from "next/link";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@stores/store";
import {
  isAuthenticatedSelector,
  userSelector,
  signOut,
} from "@stores/slices/userSlice";
import {
  LOGIN_ROUTE_PATH,
  REGISTER_ROUTE_PATH,
  MY_PROFILE_ROUTE_PATH,
  FOLLOW_IG_ROUTE_PATH,
  FOLLOW_FB_ROUTE_PATH,
  FOLLOW_LINE_ROUTE_PATH,
  FOLLOW_TIKTOK_ROUTE_PATH,
  PRODUCT_LIST_PATH,
  PRODUCT_VIEW_PATH,
  NOT_FOUND_ROUTE_PATH,
  BRANDS_ROUTE_PATH,
  FLASHSALE_ROUTE_PATH,
  CMS_ROUTE_PATH,
  SEARCH_ROUTE_PATH,
  CMS_PRIVACY_POLICY_ROUTE_PATH,
  CMS_PROTECTION_POLICY_ROUTE_PATH,
  CMS_ABOUTUS_ROUTE_PATH,
  CART_CHECKOUT_SUCCESS_ORDERID_ROUTE_PATH,
  ORDER_HISTORY_PATH,
  WISHLIST_ROUTE_PATH,
  CMS_SHOPWITH_ROUTE_PATH,
  CMS_RETURN_POLICY_ROUTE_PATH,
  CMS_WARRANTY_ROUTE_PATH,
  CMS_FAQ_ROUTE_PATH,
  CMS_TERMS_ROUTE_PATH,
  HOT_BRAND_ROUTE_PATH,
  NEW_ARRIVAL_ROUTE_PATH,
} from "@const/route-paths.const";
import { MenuResponse } from "@interfaces/dto/home-category.dto";
import { removeHtmlLegacyFromUrl } from "@utils/query.utils";
import { useRouter } from "next/router";
import { useHomeCategories } from "@api/home-category/home-category";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Minicart from "@components/features/minicart/Minicart";
import MinicartEmpty from "@components/features/minicart/NoitemCart";
import MinicartBlank from "@components/features/minicart/MinicartBlank";
import TagManager, { DataLayerArgs } from "react-gtm-module"; // DataLayer GTM
import MD5 from "crypto-js/md5"; //MD5
import TopNavigate from "@components/features/navigation/TopNavigate";
import {
  isWebview,
  clickHomeButtonNative,
  clickAccountButtonNative,
  logoutNative,
  clickWishListButtonNative,
} from "@utils/webview.util";
import SuggestSearch from "@components/features/navtop-suggestion/SuggestSearch";
import SuggestAccountMenu from "@components/features/navtop-suggestion/SuggestAccountMenu";
import { useSearchSuggest } from "@api/products/products";

interface Props {
  topMenusResponse: MenuResponse;
}

interface SearchFormValue {
  q?: string;
}

interface menuToggle {
  idx: number;
  isOpen: boolean;
}

const Header = ({ topMenusResponse }: Props) => {
  const user = useSelector(userSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const dispatch = useAppDispatch();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [activeSubmenuIdx, setActiveSubmenuIdx] = useState<menuToggle[]>([]);
  const [latestIdx, setlLatestIdx] = useState<number>(-1);

  const router = useRouter();
  const { cmskey } = router.query;
  const isCmsPrivilegeRoute =
    router.pathname === CMS_ROUTE_PATH && cmskey === "privilege";

  const { data, isLoading } = topMenusResponse;

  // Account Menu
  const [accountMenu, setAccountMenu] = useState(false);

  const {
    data: dataCats,
    isLoading: isLoadingCats,
    error: errorCats,
  } = useHomeCategories();

  const handleAciveSubmenuIdx = (idx: number) => {
    // check if idx is already in array then remove
    // if not then add
    let current = activeSubmenuIdx;
    if (idxExists(idx)) {
      setToggleIsOpen(idx, current);
      setActiveSubmenuIdx(current);
    } else {
      // not exist then set new object
      current.push({ idx, isOpen: true });
      setActiveSubmenuIdx(current);
    }

    if (idx === latestIdx) {
      setlLatestIdx(-1);
    } else {
      setlLatestIdx(idx);
    }
  };

  const idxExists = (idx: number): boolean => {
    return !!activeSubmenuIdx.find((x) => x.idx === idx);
  };

  const setToggleIsOpen = (idx: number, menuToggles: menuToggle[]): void => {
    const toggle = menuToggles.find((x) => x.idx === idx);
    if (toggle) {
      toggle.isOpen = !toggle.isOpen;
    }
  };

  // Suggest Search
  const [searchActive, setSearchActive] = useState(false);
  const [searchData, setSearchData] = useState("");

  const suggestClose = () => {
    setAccountMenu(false);
    setSearchActive(false);
  };

  const {
    data: dataSearchApi,
    isLoading: isLoadingSearch,
    error,
  } = useSearchSuggest(searchData);

  // console.log(searchActive);
  // Datalayer GTM
  useEffect(() => {
    if (isAuthenticated && user.user) {
      const variables: Record<string, any> = {
        event: "topvalue.next",
        Email: user.user?.email,
        MemberID: user.user?.id,
        Event: "setHashedEmail",
        email: MD5(user.user?.email).toString(),
      };
      const dataLayerArgs: DataLayerArgs = {
        dataLayer: { variables },
      };
      TagManager.dataLayer(dataLayerArgs);
    }
  }, [isAuthenticated, user.user?.email, user.user?.id, user.user]);

  const HEADER_DEFAULT_LAYOUT: string[] = [
    "/",
    NOT_FOUND_ROUTE_PATH,
    PRODUCT_VIEW_PATH,
    PRODUCT_LIST_PATH,
    BRANDS_ROUTE_PATH,
    FLASHSALE_ROUTE_PATH,
    CMS_SHOPWITH_ROUTE_PATH,
    CMS_ABOUTUS_ROUTE_PATH,
    CMS_PROTECTION_POLICY_ROUTE_PATH,
    CMS_RETURN_POLICY_ROUTE_PATH,
    CMS_WARRANTY_ROUTE_PATH,
    CMS_FAQ_ROUTE_PATH,
    SEARCH_ROUTE_PATH,
    SEARCH_ROUTE_PATH,
    CART_CHECKOUT_SUCCESS_ORDERID_ROUTE_PATH,
    HOT_BRAND_ROUTE_PATH,
    NEW_ARRIVAL_ROUTE_PATH,
  ];

  return (
    <header>
      <div className="header-top d-none d-lg-block">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <div className="col">
              <p className="d-none d-lg-inline-block">ติดตามเรา</p>
              <a
                href={FOLLOW_IG_ROUTE_PATH}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="mx-1 icon icon-instagram" />
              </a>
              <a
                href={FOLLOW_FB_ROUTE_PATH}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="mx-1 icon icon-facebook" />
              </a>
              <a
                href={FOLLOW_LINE_ROUTE_PATH}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="mx-1 icon icon-line" />
              </a>
              {/* <a
                href={FOLLOW_TIKTOK_ROUTE_PATH}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="mx-1 icon icon-tiktok" />
              </a> */}
            </div>
            <ul className="header-nav nav justify-content-end">
              {/* <li className="nav-item">
                <a className="nav-link" href="#">
                  ช่วยเหลือ
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  ติดตามสินค้า
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  ช้อปผ่านแอพ <i className="icon icon-mobile" />
                </a>
              </li> */}
              {isAuthenticated && user.user?.firstname ? (
                <>
                  <li
                    className="nav-item"
                    onMouseLeave={() => setAccountMenu(false)}
                  >
                    <Link href={MY_PROFILE_ROUTE_PATH}>
                      <a
                        className="nav-link"
                        onClick={(e) => {
                          if (isWebview()) {
                            e.preventDefault();
                            clickAccountButtonNative();
                          }
                        }}
                        onMouseEnter={() => setAccountMenu(true)}
                      >
                        <i className="icon icon-profile active mx-2" />
                        สวัสดี {user.user?.firstname}
                      </a>
                    </Link>
                    {accountMenu && (
                      <SuggestAccountMenu suggestClose={suggestClose} />
                    )}
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={() => {
                        dispatch(signOut());
                        if (isWebview()) {
                          logoutNative();
                        }
                      }}
                    >
                      ออกจากระบบ
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <Link href={REGISTER_ROUTE_PATH}>
                      <a className="nav-link">สมัครสมาชิก</a>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link href={LOGIN_ROUTE_PATH}>
                      <a className="nav-link">เข้าสู่ระบบ</a>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>

      <div
        className={`nav-top ${
          HEADER_DEFAULT_LAYOUT.includes(router.pathname) ||
          (isCmsPrivilegeRoute && !isAuthenticated)
            ? "d-block"
            : "d-none"
        } d-lg-block`}
      >
        <div className="container-fluid">
          <div className="bg-white pt-3 pb-3 pt-md-0 pb-md-0 rounded-2">
            <div className="row">
              <div className="col-12 col-md-3 order-lg-1 pe-md-0 d-none d-lg-block">
                <Link href="/">
                  <a>
                    <img
                      src="/assets/images/topvalue-logo.png"
                      className="topvalue-logo"
                      alt="Topvalue"
                    />
                  </a>
                </Link>
              </div>
              <div className="col-2 col-md-2 order-md-1 pe-0 d-flex d-lg-none align-items-center justify-content-center">
                <Link href="/">
                  <a
                    onClick={(e) => {
                      if (isWebview()) {
                        e.preventDefault();
                        clickHomeButtonNative();
                      }
                    }}
                  >
                    <img
                      src="/assets/images/topvalue-logo-circle.png"
                      className="img-fluid"
                      style={{ maxWidth: "50px" }}
                      alt="Topvalue"
                    />
                  </a>
                </Link>
              </div>

              <div className="col col-md-3 order-lg-4 w-40 pe-0 d-none d-lg-block">
                <div className="d-block d-md-none">
                  {/* Button trigger modal */}
                  <button
                    type="button"
                    className="btn btn-black"
                    data-bs-toggle="modal"
                    data-bs-target="#navModal"
                    onClick={handleShow}
                  >
                    <i className="icon icon-menu rounded-2" />
                  </button>
                  {/* Modal */}
                  <Modal
                    show={show}
                    onHide={handleClose}
                    fullscreen={true}
                    scrollable={true}
                  >
                    <Modal.Body>
                      <ul className="navbar-nav">
                        <span style={{ display: "none" }}>{latestIdx}</span>
                        <li className="nav-item dropdown">
                          <ul className="dropdown-menu show">
                            {data.menu_horizontal &&
                              data.menu_horizontal.map(
                                ({ label, urlpath }, idx) => {
                                  const targetPath = `/${removeHtmlLegacyFromUrl(
                                    urlpath
                                  )}`;

                                  return (
                                    <li key={idx} className="nav-item">
                                      <Link href={targetPath}>
                                        <a className="nav-link">{label}</a>
                                      </Link>
                                    </li>
                                  );
                                }
                              )}
                            {!isLoadingCats &&
                              dataCats.menu_vertical!.map((menu, idx) => (
                                <li key={idx} className="dropdown-submenu">
                                  <Link
                                    href={removeHtmlLegacyFromUrl(menu.urlpath)}
                                  >
                                    <a target="_blank" className="nav-link">
                                      {menu.label}
                                    </a>
                                  </Link>
                                  <a
                                    onClick={() => {
                                      handleAciveSubmenuIdx(idx);
                                    }}
                                    data-bs-toggle="dropdown"
                                    className="dropdown-item dropdown-toggle icon-item"
                                  />
                                  <ul
                                    className="dropdown-menu"
                                    style={
                                      activeSubmenuIdx.find(
                                        (x) => x.idx === idx
                                      )?.isOpen
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                  >
                                    {menu.sub_category_l2.length > 0 ? (
                                      <>
                                        {menu.sub_category_l2.map(
                                          (l2m, idx2) => (
                                            <li key={idx2} className="nav-item">
                                              <Link
                                                href={removeHtmlLegacyFromUrl(
                                                  l2m.urlpath
                                                )}
                                              >
                                                <a target="_blank">
                                                  {l2m.label}
                                                </a>
                                              </Link>
                                              {l2m.sub_category_l3.length >
                                              0 ? (
                                                <ul>
                                                  {l2m.sub_category_l3.map(
                                                    (l3m, idx3) => (
                                                      <li key={idx3}>
                                                        <Link
                                                          href={removeHtmlLegacyFromUrl(
                                                            l3m.urlpath
                                                          )}
                                                        >
                                                          <a>{l3m.label}</a>
                                                        </Link>
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              ) : (
                                                ""
                                              )}
                                            </li>
                                          )
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </ul>
                                </li>
                              ))}
                          </ul>
                        </li>
                      </ul>
                    </Modal.Body>
                  </Modal>
                </div>
                <div className="d-none d-md-block">
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                      <a className="btn btn-black rounded-2">
                        <i className="icon icon-menu" />
                        <span className="d-none d-md-inline-block">
                          หมวดหมู่สินค้า
                        </span>
                      </a>
                      {router.pathname !== "/" && (
                        <ul className="dropdown-menu rounded-2">
                          {!isLoadingCats &&
                            dataCats.menu_vertical!.map((menu, idx) => (
                              <li key={idx} className="dropdown-submenu">
                                <Link
                                  href={`/${removeHtmlLegacyFromUrl(
                                    menu.urlpath
                                  )}`}
                                >
                                  <a
                                    data-bs-toggle="dropdown"
                                    className="dropdown-item"
                                  >
                                    <img
                                      src={menu.icon_menu}
                                      alt={menu.label}
                                      className="icon-category"
                                    />
                                    {menu.label}
                                  </a>
                                </Link>
                                {menu.sub_category_l2.length > 0 ? (
                                  <ul className="dropdown-menu rounded-2">
                                    {menu.sub_category_l2.map((l2m, idx2) => (
                                      <li
                                        key={idx2}
                                        className="dropdown-item d-flex"
                                      >
                                        <div>
                                          <Link
                                            href={`/${removeHtmlLegacyFromUrl(
                                              l2m.urlpath
                                            )}`}
                                          >
                                            <a>{l2m.label}</a>
                                          </Link>
                                        </div>
                                        {l2m.sub_category_l3.length > 0 ? (
                                          <div className="flex-grow-1">
                                            {l2m.sub_category_l3.map(
                                              (l3m, idx3) => (
                                                <Link
                                                  key={idx3}
                                                  href={`/${removeHtmlLegacyFromUrl(
                                                    l3m.urlpath
                                                  )}`}
                                                >
                                                  <a>{l3m.label}</a>
                                                </Link>
                                              )
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </li>
                            ))}
                        </ul>
                      )}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col col-md-9 order-md-5 col-menu">
                {!isLoading && data && (
                  <div className="d-none d-lg-flex align-items-center h-100">
                    {data.menu_horizontal &&
                      data.menu_horizontal
                        .sort((a, b) => (a.position < b.position ? -1 : 1))
                        .map(({ label, urlpath }, idx) => {
                          const targetPath = `/${removeHtmlLegacyFromUrl(
                            urlpath
                          )}`;

                          return (
                            <>
                              {idx < 4 && (
                                <Link key={idx} href={targetPath}>
                                  <a
                                    className={
                                      router.asPath === targetPath
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    {label}
                                  </a>
                                </Link>
                              )}
                            </>
                          );
                        })}
                  </div>
                )}
              </div>

              <div className="col col-md-8 col-lg-6 order-md-2 flex-grow-1 align-self-center align-self-md-start align-items-center justify-content-center">
                <div className="search-box mx-lg-3">
                  <SuggestSearch
                    searchActive={searchActive}
                    dataSearch={dataSearchApi?.items}
                    isLoadingSearch={isLoadingSearch}
                    setSearchData={setSearchData}
                    setSearchActive={setSearchActive}
                  />
                  <div
                    className="input-group"
                    onClick={() => setSearchActive(true)}
                  >
                    <input
                      type="search"
                      className="form-control"
                      placeholder="ค้นหาสินค้าที่ต้องการที่นี่...."
                    />

                    <button className="btn btn-red d-none d-lg-block ">
                      <i className="icon icon-search" />
                    </button>
                  </div>
                </div>

                {/* <div className="d-flex menu ps-lg-3">
                  <a href="#">อาหารเสริม</a>
                  <a href="#">เตาปิ้งย่าง</a>
                  <a href="#">มอนิเตอร์</a>
                  <a href="#">สมาร์ทวอซ</a>
                  <a href="#">เครื่องพิมพ์</a>
                  <a href="#">มือถือ</a>
                </div> */}
              </div>

              <div className="col-2 col-lg-3 order-md-3 w-40 ps-0 align-self-center align-self-md-start">
                <div className="d-flex align-items-center contact justify-content-center">
                  <a
                    className="d-none d-lg-inline-block contact-mobile"
                    href="#"
                  >
                    <i className="text-red icon icon-mobile-contact" />
                  </a>
                  <p className="d-none mx-1 mx-lg-1  d-lg-inline-block">
                    ติดต่อเรา:
                  </p>
                  <a className="d-none d-lg-flex contact-mobile" href="#">
                    <i className="text-red icon icon-mobile" />
                    <p className="text-red">1277</p>
                  </a>
                  {isAuthenticated ? <Minicart /> : <MinicartBlank />}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="search-mobile">
            <SuggestSearch
              searchActive={searchActive}
              suggestClose={suggestClose}
              dataSearch={dataSearchApi?.items}
              isLoadingSearch={isLoadingSearch}
            />
          </div> */}
        </div>
      </div>
      {!HEADER_DEFAULT_LAYOUT.includes(router.pathname) &&
        (!isCmsPrivilegeRoute || isAuthenticated) && <TopNavigate />}
    </header>
  );
};

export default Header;
