import { AccessToken } from "@interfaces/dto/access-token.dto";
import axios from "axios";

export const fetchAccessToken = async (): Promise<AccessToken> => {
  try {
    const { data: accessToken } = await axios.post(`/api/token`);
    return accessToken;
  } catch (error: any) {
    return undefined;
  }
};
