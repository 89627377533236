import { LoginDto, SocialLoginDto, UserState } from "@interfaces/dto/user-data.dto";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@stores/store";
import * as serverService from "@api/routes/customer-authen";
import Router from "next/router";
import { LOGIN_ROUTE_PATH } from "@const/route-paths.const";
import { signOut as Logout } from "next-auth/react";
import { AccessToken } from "@interfaces/dto/access-token.dto";
import { removeRecentViewIdStorage } from "@stores/storages/recent-view.storage";

// Initial
const initialState: UserState = {
  accessToken: "",
  isAuthenticated: false,
  isAuthenticating: true,
  user: undefined,
};

// Login Action
export const signIn = createAsyncThunk(
  "user/signin",
  async (credential: LoginDto) => {
    const response = await serverService.signIn(credential);
    if (response === undefined) {
      throw new Error("Login failed");
    }
    return response;
  }
);

// fetchSession Action
export const getSession = createAsyncThunk("user/fetchSession", async () => {
  const response = await serverService.getSession();
  return response;
});

// Logout Action
export const signOut = createAsyncThunk("user/signout", async () => {
  const response = await serverService.signOut();
  await Logout({
    redirect: false,
    callbackUrl: "/"
  })
  removeRecentViewIdStorage()
});

// Social Login Action
export const socialLogin = createAsyncThunk("user/socialLogin", async(credential: SocialLoginDto) => {
  const response = await serverService.socialLogin(credential);
  if (response === undefined) {
    throw new Error("Login failed");
  }
  return response;
})

// Force Login Action
export const forceLogin = createAsyncThunk("user/forceLogin", async(accessToken: AccessToken) => {
  const response = await serverService.forceLogin(accessToken);
  if (response === undefined) {
    throw new Error("Login failed");
  }
  return response
})

// Reducers
const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Login completed
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.accessToken = action.payload;
      state.isAuthenticated = true;
      state.isAuthenticating = false;
      state.user = undefined;
    });

    // Login Fail
    builder.addCase(signIn.rejected, (state, _action) => {
      state.accessToken = "";
      state.isAuthenticated = false;
      state.isAuthenticating = false;
      state.user = undefined;
    });

    // Logout
    builder.addCase(signOut.fulfilled, (state, _action) => {
      state.accessToken = "";
      state.isAuthenticated = false;
      state.isAuthenticating = false;
      state.user = undefined;
    });

    // Get Session
    builder.addCase(getSession.fulfilled, (state, action) => {
      state.isAuthenticating = false;
      if (action.payload && action.payload.token && action.payload.user) {
        state.accessToken = action.payload.token;
        state.isAuthenticated = true;
        state.user = action.payload.user;
      }
    });

    // SocialLogin completed
    builder.addCase(socialLogin.fulfilled, (state, action) => {
      state.accessToken = action.payload;
      state.isAuthenticated = true;
      state.isAuthenticating = false;
      state.user = undefined;
    });    

    // Force Login
    builder.addCase(forceLogin.fulfilled, (state, action) => {
      state.accessToken = action.payload;
      state.isAuthenticated = true;
      state.isAuthenticating = false;
      state.user = undefined;
    });   
  },
});

// export common user selector
export const userSelector = (store: RootState) => store.user;
export const isAuthenticatedSelector = (store: RootState): boolean =>
  store.user.isAuthenticated;
export const isAuthenticatingSelector = (store: RootState): boolean =>
  store.user.isAuthenticating;

// export reducer
export default userSlice.reducer;
