import { RECENT_VIEW_ID_STORAGE_KEY } from "@const/storage-keys.const";

export const setRecentViewIdStorage = (categoryId: string | undefined) => {
    if (categoryId) {
      window.localStorage.setItem(RECENT_VIEW_ID_STORAGE_KEY, categoryId);
    }
  };

export const removeRecentViewIdStorage = () => {
  window.localStorage.removeItem(RECENT_VIEW_ID_STORAGE_KEY);
};