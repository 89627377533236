import { fetchAccessToken } from "@api/routes/acess-token";
import { LOGIN_ROUTE_PATH } from "@const/route-paths.const";
import { AccessToken } from "@interfaces/dto/access-token.dto";
import {
  getAccessTokenStorage,
  removeAccessTokenStorage,
  setAccessTokenStorage,
} from "@stores/storages/access-token.storage";
import axios from "axios";

import { getCookies } from "cookies-next";

const getUserAccessTokenCookie = (): string => {
  const accessToken = getCookies();
  return accessToken.ACCESS_TOKEN_KEY as string;
};

export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
});

api.interceptors.request.use(async (config) => {
  let accessToken = getUserAccessTokenCookie();
  // console.log("## Process api request interceptor.");
  // console.log("Extract access token from storage:", accessToken);
  // in case access token does not exist in storage
  // if (!accessToken) {
  //   accessToken = await fetchAccessTokenThenSetStorage();
  //   // console.log(
  //   //   "Fetch access token as it does not exist in storage:",
  //   //   accessToken
  //   // );
  // }
  config.headers!["Authorization"] = `Bearer ${accessToken}`;
  return config;
});

api.interceptors.response.use(
  (res) => res,
  async (err) => {
    // console.log("## Process api response error.");
    // in case access token expired
    if (err.response.status === 401) {
      // const accessToken = await fetchAccessTokenThenSetStorage();
      // // console.log("Fetch access token as it expires:", accessToken);
      // if (accessToken) {
      //   // console.log("Get new access token.")
      //   err.config.headers["Authorization"] = `Bearer ${accessToken}`;
      //   return axios
      //     .request(err.config)
      //     .then((res) => Promise.resolve(res))
      //     .catch((e) => Promise.reject(e));
      // } else {
      //   removeAccessTokenStorage();
      //   window.location.href = "/404";
      //   return Promise.reject(err);
      // }

      return Promise.reject(err);
    }
    // other error
    // console.error("Get other error from api response:", err);
    return Promise.reject(err);
  }
);

export const fetcher = (url: string): any =>
  api.get(url).then((res: any) => res.data);
