import useSWR from "swr";
import { api, fetcher } from "@api/_base";
import { fetcher as userFecther } from "@api/_base_user_optional";
import { getFilterQuery, getPageQuery, getSortOrdersQuery } from "./_utils";
import { composeQueryParams } from "@utils/query.utils";
import { ProductDisplayConfig } from "@interfaces/components/product-filters.interface";
import {
  CateProductsReponse,
  ProductImage,
  RelatedProductsResponse,
  UpsellsProductsResponse,
  SeachSuggestReponse,
} from "@interfaces/dto/products.dto";

import {
  ProductDetailResponse,
  ProductKeyExchangeResponse,
  ProductWishlistCheckedResponse,
} from "@interfaces/dto/product-detail.dto";

export const useRelatedProducts = ({
  categoryId,
  neqProductId,
  productDisplayConfig,
}: {
  categoryId: string | undefined;
  neqProductId?: string | undefined;
  productDisplayConfig: ProductDisplayConfig;
}): RelatedProductsResponse => {
  let query: string | undefined = undefined;
  // require categoryId
  if (categoryId) {
    const queryCat = getFilterQuery({
      field: "category_id",
      value: categoryId,
      layer: 0,
    });
    // [page]
    const { pageSize, currentPage, sortBy } = productDisplayConfig;
    const queryPage = getPageQuery({
      pageSize: pageSize,
      currentPage: currentPage,
      sortBy: sortBy
    });

    //Not include current view product
    let queryNeq = undefined
    if(neqProductId){
      queryNeq = `searchCriteria[filterGroups][0][filters][1][field]=entity_id&searchCriteria[filterGroups][0][filters][1][value]=${neqProductId}&searchCriteria[filterGroups][0][filters][1][conditionType]=neq`
    }
    query = composeQueryParams([queryCat, queryPage, queryNeq]);
  }

  const { data, error, mutate } = useSWR(
    query ? `/nextapi/products-related?${query}` : undefined,
    fetcher
  );

  return {
    data: data,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export const getProductImage = async (
  productId: string
): Promise<ProductImage | undefined> => {
  const response = await api.get(`/getproductimage/order/item/${productId}`);
  return response && response.data && response.data.length > 0
    ? { ...response.data[0], item_id: Number(productId) }
    : undefined;
};

export const useUpsellsProducts = ({
  categoryId,
  neqProductId,
  productDisplayConfig,
}: {
  categoryId: string | undefined;
  neqProductId: string | undefined;
  productDisplayConfig: ProductDisplayConfig;
}): UpsellsProductsResponse => {
  let query: string | undefined = undefined;
  // require categoryId
  if (categoryId) {
    const queryCat = getFilterQuery({
      field: "category_id",
      value: categoryId,
      layer: 0,
    });
    // [page]
    const { pageSize, currentPage, sortBy } = productDisplayConfig;
    const queryPage = getPageQuery({
      pageSize: pageSize,
      currentPage: currentPage,
      sortBy: sortBy
    });

    //Not include current view product
    const queryNeq = `searchCriteria[filterGroups][0][filters][1][field]=entity_id&searchCriteria[filterGroups][0][filters][1][value]=${neqProductId}&searchCriteria[filterGroups][0][filters][1][conditionType]=neq`

    query = composeQueryParams([queryCat, queryPage, queryNeq]);
  }

  const { data, error, mutate } = useSWR(
    query ? `/nextapi/products-upsell?${query}` : undefined,
    fetcher
  );

  return {
    data: data,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export const useProducts = ({
  categoryId,
  subcatIds,
  priceRange,
  brandIds,
  productDisplayConfig,
}: {
  categoryId: string | undefined;
  subcatIds: number[] | undefined;
  priceRange: string | undefined;
  brandIds: number[] | undefined;
  productDisplayConfig: ProductDisplayConfig;
}): CateProductsReponse => {
  let query: string | undefined = undefined;
  // require categoryId
  if (categoryId) {
    // [cat]
    const queryCat = getFilterQuery({
      field: "category_id",
      value:
        subcatIds && subcatIds.length > 0 ? subcatIds.join(",") : categoryId,
      layer: 0,
      cond: "in",
    });
    // [price]
    const queryPriceStart = priceRange
      ? getFilterQuery({
        field: "price",
        value: priceRange.split("-")[0],
        layer: 1,
        cond: "gteq",
      })
      : undefined;
    const queryPriceEnd = priceRange
      ? getFilterQuery({
        field: "price",
        value: priceRange.split("-")[1],
        layer: 2,
        cond: "lteq",
      })
      : undefined;
    // [brand]
    const queryBrand =
      brandIds && brandIds.length > 0
        ? getFilterQuery({
          field: "brand",
          value: brandIds.join(","),
          layer: 3,
          cond: "in",
        })
        : undefined;
    // [page]
    const { pageSize, currentPage, sortBy, soryDirection } =
      productDisplayConfig;
    const queryPage = getPageQuery({
      pageSize: pageSize,
      currentPage: currentPage,
      sortBy: sortBy,
      soryDirection: soryDirection,
    });

    // Optional SortOrders Query
    const optionalSortQuery = getSortOrdersQuery({
      field: "entity_id",
      direction: "ASC",
      layer: 1
    })
    query = composeQueryParams([
      queryCat,
      queryPriceStart,
      queryPriceEnd,
      queryBrand,
      queryPage,
      optionalSortQuery,
    ]);
  }

  const { data, error, mutate } = useSWR(
    query ? `/nextapi/products?${query}` : undefined,
    fetcher
  );

  return {
    data: data,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export const useBrandProducts = ({
  priceRange,
  brandId,
  productDisplayConfig,
}: {
  priceRange: string | undefined;
  brandId: number | undefined;
  productDisplayConfig: ProductDisplayConfig;
}): CateProductsReponse => {
  let query: string | undefined = undefined;
  // require categoryId
  if (brandId) {
    // [price]
    const queryPriceStart = priceRange
      ? getFilterQuery({
        field: "price",
        value: priceRange.split("-")[0],
        layer: 1,
        cond: "gteq",
      })
      : undefined;
    const queryPriceEnd = priceRange
      ? getFilterQuery({
        field: "price",
        value: priceRange.split("-")[1],
        layer: 2,
        cond: "lteq",
      })
      : undefined;
    // [brand]
    const queryBrand = getFilterQuery({
      field: "brand",
      value: brandId.toString(),
      layer: 3,
      cond: "in",
    });
    // [page]
    const { pageSize, currentPage, sortBy, soryDirection } =
      productDisplayConfig;
    const queryPage = getPageQuery({
      pageSize: pageSize,
      currentPage: currentPage,
      sortBy: sortBy,
      soryDirection: soryDirection,
    });

    query = composeQueryParams([
      queryPriceStart,
      queryPriceEnd,
      queryBrand,
      queryPage,
    ]);
  }

  const { data, error, mutate } = useSWR(
    query ? `/nextapi/products?${query}` : undefined,
    fetcher
  );

  return {
    data: data,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export const useSearchProducts = ({
  searchQ,
  productDisplayConfig
}: {
  searchQ: string | undefined;
  productDisplayConfig: ProductDisplayConfig;
}): CateProductsReponse => {
  let query: string | undefined = undefined;
  // require categoryId
  if (searchQ) {
    // [price]
    const querySearch = getFilterQuery({
      field: "search_term",
      value: searchQ,
      layer: 0,
    });

    // [page]
    const { pageSize, currentPage, sortBy, soryDirection } =
      productDisplayConfig;
    const queryPage = getPageQuery({
      pageSize: pageSize,
      currentPage: currentPage,
      sortBy: sortBy,
      soryDirection: soryDirection,
    });

    query = composeQueryParams([
      querySearch,
      queryPage,
    ]);
  }

  const { data, error, mutate } = useSWR(
    query ? `/nextapi/search?${query}` : undefined,
    fetcher
  );

  return {
    data: data,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export const useSearchSuggest = ( 
  searchQ: string | undefined,
): SeachSuggestReponse => {
  let query: string | undefined = undefined;
  // require product or brand name
  if (searchQ) {
    // Keyword
    const querySearch = getFilterQuery({
      field: "search_term",
      value: searchQ,
      layer: 0,
    });

    query = composeQueryParams([
      querySearch,
    ]);
  }

  const { data, error, mutate } = useSWR(
    query ? `/nextapi/popupsearch?${query}` : undefined,
    fetcher
  );

  return {
    data: data,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export const useProductDetail = (
  productId: string | undefined
): ProductDetailResponse => {
  const { data, error, mutate } = useSWR(
    productId ? `/nextapi/products/${productId}` : undefined,
    fetcher
  );

  return {
    data: data && data.length > 0 ? data[0] : undefined,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export const useProductKeyExchange = (
  productKey: string | undefined
): ProductKeyExchangeResponse => {
  const { data, error, mutate } = useSWR(
    productKey ? `/nextapi/type?urlKey=${productKey}` : undefined,
    fetcher
  );

  return {
    data: data && data.length > 0 ? data[0] : undefined,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export const useProductWishlistChecked = (
  productId: number | null 
): ProductWishlistCheckedResponse => {
  const response = useSWR(
    productId !== null ? `/wishlist/${productId}` : undefined,
    userFecther
  );

  const { data, error, mutate } = response;

  return {
    data: data,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};
