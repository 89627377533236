import { publicApi } from "@api/_public";
import { API_MESSAGE } from "@const/api-messages.const";
import { AccessToken } from "@interfaces/dto/access-token.dto";
import { ApiResponse } from "@interfaces/dto/swr.dto";
import { CreateAccountDto, LoginDto, SocialLoginDto } from "@interfaces/dto/user-data.dto";
import axios from "axios";

export const createAccount = async (
  inputs: CreateAccountDto
): Promise<ApiResponse> => {
  try {
    await publicApi.post(`/customers`, inputs);
    return { isOk: true, message: "สมัครสมาชิกเสร็จสมบูรณ์" };
  } catch (error: any) {
    const errorMessage: string =
      error.response.data.message ?? API_MESSAGE.INTERNAL_ERROR;
    return { isOk: false, message: errorMessage };
  }
};

export const signIn = async (user: LoginDto): Promise<AccessToken> => {
  try {
    const { data: accessToken } = await axios.post(`/api/userauth/signin`, user);
    return accessToken;
  } catch (error: any) {
    return undefined;
  }
};

export async function signOut() {
  try {
    const response = await axios.get(`/api/userauth/signout`);
    return response.data;
  } catch (error: any) {
    return undefined;
  }
}

export const getSession = async (): Promise<any> => {
  try {
    const response = await axios.get(`/api/userauth/session`);
    return response.data;
  } catch (error: any) {
    return undefined;
  }
};

export const socialLogin = async (user: SocialLoginDto): Promise<AccessToken> => {
  try {
    const { data: accessToken } = await axios.post(`/api/userauth/sociallogin`, user);
    return accessToken;
  } catch (error: any) {
    return undefined;
  }
};

export const forceLogin = async (token: AccessToken): Promise<any> => {
  try {
    const response = await axios.post(`/api/userauth/refreshtoken`, {
      secret: token
    });
    return response.data;
  } catch (error: any) {
    return undefined;
  }
};