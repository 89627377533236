export const LOGIN_ROUTE_TITLE = "เข้าสู่ระบบ / สมัครสมาชิก";
export const FORGOT_ROUTE_TITLE = "รีเซตรหัสผ่าน";
export const CART_ROUTE_TITLE = "รถเข็น";
export const MY_PROFILE_ROUTE_TITLE = "ข้อมูลส่วนตัว"
export const MY_ORDERS_ROUTE_TITLE = "รายการสั่งซื้อ"
export const MY_COUPON_ROUTE_TITLE = "คูปองของฉัน"
export const MY_ADDRESSES_ROUTE_TITLE = "ที่อยู่จัดส่งสินค้า"
export const MY_TAX_ROUTE_TITLE = "ที่อยู่ใบกำกับภาษี"
export const MY_CHANGEPASS_ROUTE_TITLE = "เปลี่ยนรหัสผ่าน"
export const MY_WISHLIST_ROUTE_TITLE = "รายการสินค้าโปรด"
export const MY_REVIEW_ROUTE_TITLE = "รีวิวสินค้า"
export const MY_PRIVACY_ROUTER_TITLE = "ตั้งค่าความเป็นส่วนตัว"
export const CHECKOUT_ROUTE_TITLE = "ชำระเงิน"
export const CATEGORIES_ROUTE_TITLE = "หมวดหมู่สินค้า"
export const CMS_MAIN_ROUTE_TITLE = "คู่มือนักช้อป"
export const CMS_RETURN_POLICY_ROUTE_TITLE = "วิธีเปลี่ยนและคืนสินค้า"
export const CMS_WARRANTY_ROUTE_TITLE = "การรับประกันสินค้า"
export const CMS_FAQ_ROUTE_TITLE = "คำถามที่พบบ่อย"
export const CMS_PROTECTION_POLICY_ROUTE_TITLE = "นโยบายการคุ้มครองผู้ซื้อ"
export const CMS_TERMS_ROUTE_TITLE = "ข้อกำหนดและเงื่อนไข";
export const CMS_PRIVACY_POLICY_ROUTE_TITLE = "นโยบายความเป็นส่วนตัว";
export const NEW_ARRIVAL_TITLE = "สินค้าใหม่";