import { DropdownOption } from "@interfaces/components/dropdown.interface";
import {
  ProductSortBy,
} from "@interfaces/components/product-filters.interface";
import { RangeValues } from "@interfaces/components/range-slide.interface";

// price range filter
export const PRODUCT_PRICE_FILTER_STEP = 1;

// product display filter
export const PRODUCT_DEFAULT_SORT_BY: ProductSortBy = "position";
export const PRODUCT_SORT_LIST: DropdownOption[] = [
  { value: "name", label: "ชื่อ" },
  { value: "position", label: "ตำแหน่ง" },
  { value: "price_desc", label: "ราคา สูง - ต่ำ", desc: "DESC" },
  { value: "price_asc", label: "ราคา ต่ำ - สูง", desc: "ASC" },
];

// product group display
export const PRODUCT_GROUP_DEFAULT_SORT_BY: ProductSortBy = "random";

// product paging
export const PRODUCT_DEFAULT_PAGE = 1;
export const PRODUCT_PAGE_SIZE = 16;

// product images
export const PRODUCT_NOT_FOUND_IMG = "/assets/images/product-not-found.jpg";

// brand display
export const MAX_BRAND_ITEMS = 10;

// page layout
export const LAYOUT_DEFAULT = "no-layout"
export const LAYOUT_1_COLUMN = "1column"

// grid display
export const DEFAULT_ITEMS_PER_ROW = "row-cols-2 row-cols-md-4 row-cols-lg-4";
export const EXTRA_ITEMS_PER_ROW = "row-cols-2 row-cols-md-4 row-cols-lg-5";

//review paging
export const REVIEW_DEFAULT_PAGE = 1;
export const REVIEW_PAGE_SIZE = 3;

//upsell paging
export const UPSELL_DEFAULT_PAGE = 1;
export const UPSELL_PAGE_SIZE = 5;

//related paging
export const RELATED_DEFAULT_PAGE = 1;
export const RELATED_PAGE_SIZE = 5;

//coupon paging
export const COUPON_DEFAULT_PAGE = 1;
export const COUPON_PAGE_SIZE = 6;