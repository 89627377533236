import Link from "next/link";
import { useRouter } from "next/router";
import {
  CART_PRODUCTS_ROUTE_PATH,
  MY_PROFILE_ROUTE_PATH,
  EDIT_PROFILE_ROUTE_PATH,
  ADDRESSS_LIST_ROUTE_PATH,
  CHANGE_PASSWORD_ROUTE_PATH,
  WISHLIST_ROUTE_PATH,
  ACCOUNT_PRIVACY_SETTING_PATH,
  ORDER_HISTORY_PATH,
  PRODUCT_VIEW_PATH,
  CART_CHECKOUT_ROUTE_PATH,
} from "@const/route-paths.const";
import { useSelector } from "react-redux";
import { isAuthenticatedSelector } from "@stores/slices/userSlice";
import IncartTotal from "../minicart/IncartTotal";
import {
  isWebview,
  clickHomeButtonNative,
  clickWishListButtonNative,
  clickCategoryButtonNative,
  clickAccountButtonNative,
  clickCartButtonNative,
} from "@utils/webview.util";

type Props = {
  handleClose?: () => void;
  handleShow?: () => void | undefined;
  handleShowCanvas?: () => void;
  isSaleable?: boolean;
  isLoading?: boolean;
  isWishlist?: boolean;
  onUpdateWishList?: ((isWishlist: boolean) => void) | undefined;
};

const BottomMenus = ({
  handleClose,
  handleShow,
  handleShowCanvas,
  isSaleable,
  isLoading,
  isWishlist,
  onUpdateWishList,
}: Props) => {
  const router = useRouter();
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  return (
    <>
      <nav className="nav nav-pills nav-justified">
        {/* Product view page */}
        {router.pathname === PRODUCT_VIEW_PATH && (
          <>
            <Link href="/">
              <a
                className={`nav-link py-3 px-0 ${
                  router.asPath === "/" ? "active" : ""
                }`}
                aria-current="page"
                onClick={(e) => {
                  if (isWebview()) {
                    e.preventDefault();
                    clickHomeButtonNative();
                  }
                }}
              >
                <i className="icon icon-home" />
                <p>หน้าหลัก</p>
              </a>
            </Link>
            {onUpdateWishList && (
              <>
                <a
                  className={`nav-link py-3 px-0`}
                  onClick={() => {
                    if (isWebview() && !isAuthenticated) {
                      clickAccountButtonNative();
                      return;
                    }
                    onUpdateWishList(!isWishlist);
                  }}
                >
                  <i
                    className={`icon icon-wishlist ${isWishlist && "active"}`}
                  />
                  <p>สินค้าโปรด</p>
                </a>
              </>
            )}

            <div className="d-flex align-items-center flex-grow-1">
              <div className="d-grid col">
                <a
                  className={`btn ${
                    !isSaleable && !isLoading ? "disabled" : "btn-red"
                  } mx-3`}
                  role="button"
                  onClick={handleShowCanvas}
                >
                  {!isSaleable ? "สินค้าหมด" : "เพิ่มลงรถเข็น"}
                </a>
              </div>
            </div>
          </>
        )}

        {router.pathname !== CART_CHECKOUT_ROUTE_PATH &&
          router.pathname !== PRODUCT_VIEW_PATH && (
            <>
              <Link href="/">
                <a
                  className={`nav-link py-3 ${
                    router.asPath === "/" ? "active" : ""
                  }`}
                  aria-current="page"
                  onClick={(e) => {
                    if (isWebview()) {
                      e.preventDefault();
                      clickHomeButtonNative();
                    }
                  }}
                >
                  <i className="icon icon-home" />
                  <p>หน้าหลัก</p>
                </a>
              </Link>
              {handleShow && (
                <a
                  className={`nav-link py-3`}
                  data-bs-toggle="offcanvas"
                  role="button"
                  aria-controls="offcanvasCategory"
                  onClick={() => {
                    handleShow();
                    if (isWebview()) {
                      clickCategoryButtonNative();
                    }
                  }}
                >
                  <i className="icon icon-category" />
                  <p>หมวดหมู่</p>
                </a>
              )}
              <Link href={WISHLIST_ROUTE_PATH}>
                <a
                  className={`nav-link py-3 ${
                    router.asPath === WISHLIST_ROUTE_PATH && isAuthenticated
                      ? "active"
                      : ""
                  }`}
                  onClick={(e) => {
                    if (isWebview()) {
                      e.preventDefault();
                      if (isAuthenticated) {
                        clickWishListButtonNative();
                      } else {
                        clickAccountButtonNative();
                      }
                    }
                  }}
                >
                  <i className="icon icon-wishlist" />
                  <p>สินค้าโปรด</p>
                </a>
              </Link>

              <Link href={CART_PRODUCTS_ROUTE_PATH}>
                <a
                  className={`nav-link py-3 ${
                    router.asPath === CART_PRODUCTS_ROUTE_PATH &&
                    isAuthenticated
                      ? "active"
                      : ""
                  }`}
                  onClick={(e) => {
                    if (isWebview()) {
                      e.preventDefault();
                      if (isAuthenticated) {
                        clickCartButtonNative();
                      } else {
                        clickAccountButtonNative();
                      }
                    }
                  }}
                >
                  {!isAuthenticated ? (
                    <>
                      <i className="icon icon-cart">
                        <span className="minicart-qty rounded-1">0</span>
                      </i>
                      <p>รถเข็น</p>
                    </>
                  ) : (
                    <IncartTotal />
                  )}
                </a>
              </Link>
              <Link href={MY_PROFILE_ROUTE_PATH}>
                <a
                  className={`nav-link py-3 ${
                    (!isAuthenticated && router.asPath !== "/") ||
                    router.asPath === MY_PROFILE_ROUTE_PATH ||
                    router.asPath === EDIT_PROFILE_ROUTE_PATH ||
                    router.asPath === ADDRESSS_LIST_ROUTE_PATH ||
                    router.asPath === CHANGE_PASSWORD_ROUTE_PATH ||
                    router.asPath === ACCOUNT_PRIVACY_SETTING_PATH ||
                    router.asPath === ORDER_HISTORY_PATH
                      ? "active"
                      : ""
                  }`}
                  onClick={(e) => {
                    if (isWebview()) {
                      e.preventDefault();
                      clickAccountButtonNative();
                    }
                  }}
                >
                  <i className="icon icon-profile" />
                  <p>บัญชี</p>
                </a>
              </Link>
            </>
          )}
      </nav>
    </>
  );
};

export default BottomMenus;
