import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import TabsNavigate from "./TabsNavigate";
import BottomMenus from "./BottomMenus";
import Link from "next/link";
import {
  CART_CHECKOUT_ROUTE_PATH,
  CART_PRODUCTS_ROUTE_PATH,
} from "@const/route-paths.const";
import { useRouter } from "next/router";

type Props = {};

const BottomNavigate = (props: Props) => {
  const router = useRouter();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* OffCanvas */}
      <Offcanvas className="w-100" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>หมวดหมู่</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* Navigate */}
          <TabsNavigate handleCloseOffcanvas={handleClose} />
        </Offcanvas.Body>
      </Offcanvas>

      <div className="bottom-navigate fixed-bottom bg-white d-block d-lg-none">
        {router.pathname === CART_PRODUCTS_ROUTE_PATH && (
          <div className="d-grid d-lg-flex align-items-lg-center p-3">
            <Link href={CART_CHECKOUT_ROUTE_PATH}>
              <a className="btn btn-red2 me-sm-1" title="ดำเนินการสั่งซื้อ">
                ดำเนินการสั่งซื้อ
              </a>
            </Link>
          </div>
        )}
        <BottomMenus handleClose={handleClose} handleShow={handleShow} />
      </div>
    </>
  );
};

export default BottomNavigate;
