import { api } from "@api/_base";
import { api as userApi, fetcher } from "@api/_base_user";
import { API_MESSAGE } from "@const/api-messages.const";
import { CartItemDTO, CartSimpleItem } from "@interfaces/dto/cart-item.dto";
import { ProductDetailShort } from "@interfaces/dto/product-detail.dto";
import { ApiResponse } from "@interfaces/dto/swr.dto";
import {
  CartPointsResponse,
  CartProductQtyUpdateDto,
  CartProductsResponse,
} from "@interfaces/dto/cart-checkout.dto";
import useSWR from "swr";

export const getCartId = async (): Promise<ApiResponse> => {
  try {
    const { data } = await userApi.post(`/carts/mine`);
    return { isOk: true, message: "", data: data };
  } catch (error: any) {
    const errorMessage: string =
      error.response.data.message ?? API_MESSAGE.INTERNAL_ERROR;
    return { isOk: false, message: errorMessage };
  }
};

export const updateCartProductQty = async (
  productId: number,
  cartProductQtyUpdateDto: CartProductQtyUpdateDto
): Promise<ApiResponse> => {
  try {
    await userApi.put(
      `/carts/mine/items/${productId}`,
      cartProductQtyUpdateDto
    );
    return { isOk: true, message: "" };
  } catch (error: any) {
    const errorMessage: string =
      error.response.data.message ?? API_MESSAGE.INTERNAL_ERROR;
    return { isOk: false, message: errorMessage };
  }
};

export const deleteCartProduct = async (
  productId: number
): Promise<ApiResponse> => {
  try {
    await userApi.delete(`/carts/mine/items/${productId}`);
    return { isOk: true, message: "ลบสินค้าออกจากรถเข็น" };
  } catch (error: any) {
    const errorMessage: string =
      error.response.data.message ?? API_MESSAGE.INTERNAL_ERROR;
    return { isOk: false, message: errorMessage };
  }
};

export const getProductDetail = async (
  productId: string
): Promise<ProductDetailShort | undefined> => {
  const response = await api.get(`/getproductimage/cart/item/${productId}`);
  return response && response.data && response.data.length > 0
    ? { ...response.data[0], item_id: Number(productId) }
    : undefined;
};

export const useCartProducts = (): CartProductsResponse => {
  const { data, error, mutate } = useSWR(
    // "/carts/mine/totals?fields=items_qty,items[item_id,price,qty,row_total,discount_amount,name,options],total_segments",
    "/carts/mine/totals?fields=items_qty,items[item_id,price,qty,row_total,discount_amount,name,options,extension_attributes[regular_price]],total_segments",
    fetcher
  );

  return {
    data: data,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export const addItemToCart = async (
  cartItem: CartItemDTO
): Promise<ApiResponse> => {
  try {
    const { data } = await userApi.post(`/carts/mine/items`, cartItem);
    return { isOk: true, message: "", data: data };
  } catch (error: any) {
    const errorMessage: string =
      error.response.data.message ?? API_MESSAGE.INTERNAL_ERROR;
    return { isOk: false, message: errorMessage };
  }
};

export const useCartPoints = (): CartPointsResponse => {
  const { data, error, mutate } = useSWR(
    "/rewards/mine/highlight/checkout",
    fetcher
  );

  return {
    data: data,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};
