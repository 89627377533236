import { useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";

interface Props {
  heightPixel?: string;
}

const Loader = ({ heightPixel }: Props) => {
  let [loading, _setLoading] = useState<boolean>(true);
  let [color, _setColor] = useState<string>("#f89797");

  heightPixel = heightPixel !== undefined ? heightPixel : "100";

  const styleObject = {
    height: `${heightPixel}px`,
    width: "100%",
  };

  return (
    <div className="text-center sweet-loading" style={styleObject}>
      <PulseLoader
        color={color}
        loading={loading}
        size={10}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loader;
