import React, { useEffect, useState } from "react";
import { isWebview } from "@utils/webview.util";

interface Props {
  appStoreUrl: string;
  playStoreUrl: string;
}

const SmartBanner = ({ appStoreUrl, playStoreUrl }: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent);
    const isAndroid = /android/i.test(userAgent);
    if (isWebview()) {
      setIsVisible(false);
    } else {
      if ((isIOS && appStoreUrl) || (isAndroid && playStoreUrl)) {
        setIsVisible(true);
      }
    }
  }, [appStoreUrl, playStoreUrl]);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="smart-banner">
      <div className="smart-banner-content py-2 pe-1">
        <div className="smart-banner-info d-flex">
          <div className="text-center px-2">
            <button className="smart-banner-close" onClick={handleClose}>
              x
            </button>
          </div>
          <div className="">
            <img
              src="/assets/images/icon-app.png"
              alt=""
              className="img-fluid"
              width={30}
            />
          </div>
          <div className="smart-banner-desc align-self-center flex-fill ps-2">
            <p className="flex-grow-1 pb-1">
              <strong>ใช้แอป TOPVALUE</strong>
            </p>
            <p className="align-self-start text-secondary">
              ช้อปสะดวก ผ่านแอพพลิเคชั่น โหลดเลย
            </p>
          </div>
          <a
            href={
              navigator.userAgent.match(/iPad|iPhone|iPod/)
                ? appStoreUrl
                : playStoreUrl
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="smart-banner-button flex-fill p-2">
              USE APP
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SmartBanner;
