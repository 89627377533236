import { ApiResponse } from "@interfaces/dto/swr.dto";
import { toast } from "react-toastify";

export const successToast = (message: string) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    style: {
      fontFamily: "Sukhumvit-Set",
      fontSize: "14px",
    },
  });
};

export const errorToast = (message: string) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    style: {
      fontFamily: "Sukhumvit-Set",
      fontSize: "14px",
    },
  });
};

export const commonToast = ({ isOk, message }: ApiResponse) => {
  isOk ? successToast(message) : errorToast(message);
};
