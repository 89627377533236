import { useCartProducts } from "@api/customer/cart";
import React from "react";

type Props = {};

const IncartTotal = (props: Props) => {
  const { data, isLoading, error, mutate } = useCartProducts();
  return (
    <>
      <i className="icon icon-cart">
        <span className="minicart-qty rounded-1">
          {data ? data.items_qty : 0}
        </span>
      </i>
      <p>รถเข็น</p>
    </>
  );
};

export default IncartTotal;
