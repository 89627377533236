// TODO: update message
export const API_MESSAGE = {
  // FETCHED_SUCCESSFULLY: "Fetched successfully.",
  // CREATED_SUCCESSFULLY: "Created successfully.",
  // UPDATED_SUCCESSFULLY: "Updated successfully.",
  // UPLOADED_FILES_SUCCESSFULLY: "Uploaded files successfully.",
  INTERNAL_ERROR: "ไม่สามารถเชื่อต่อกับเซิร์ฟเวอร์ได้ กรุณาลองใหม่อีกครั้ง",
  // MAP_ERROR: "Map server error. Try again.",
  // CLIENT_ERROR: "Client error. Refresh the page and try again.",
};
