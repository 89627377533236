import Link from "next/link";
import { useState, useEffect, useRef } from "react";
import { itemSuggest } from "@interfaces/dto/products.dto";
import Loader from "@components/atoms/Loader";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { PRODUCT_NOT_FOUND_IMG } from "@const/products.const";

interface Props {
  searchActive: boolean;
  dataSearch: itemSuggest[] | undefined;
  isLoadingSearch: boolean;
  setSearchData: (data: string) => void;
  setSearchActive: (active: boolean) => void;
}

const SuggestSearch = ({
  searchActive,
  dataSearch,
  isLoadingSearch,
  setSearchData,
  setSearchActive,
}: Props) => {
  // console.log(dataSearch);
  const router = useRouter();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm();
  const [inputValue, setInputValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [boxShadowStyle, setBoxShadowStyle] = useState({});

  const handleClosePopup = () => {
    setSearchActive(false);
    setInputValue("");
  };

  // const style = {
  //   boxShadow:
  //     inputValue.length >= 3 ? "5px 0 0 5px rgba(0, 0, 0, 0.05)" : "none",
  // };

  // RouteChange
  useEffect(() => {
    const handleRouteChange = () => {
      setSearchActive(false);
      setInputValue("");
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router, setSearchActive]);

  // InputValue
  useEffect(() => {
    if (inputValue.length >= 3) {
      setSearchActive(true);
      setSearchData(inputValue);
      setDropdown(true);
      // setBoxShadowStyle({
      //   boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.05)",
      // });
      // console.log("active", searchActive);
    } else {
      setDropdown(false);
      // setBoxShadowStyle({
      //   boxShadow: "none",
      // });
    }
  }, [inputValue, setSearchData, setSearchActive]);

  // FormRef
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (formRef.current && !formRef.current.contains(event.target as Node)) {
        setSearchActive(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [setSearchActive]);

  const onSubmitHandler = async () => {
    if (inputValue) {
      router.push(`/search?q=${inputValue}`);
      setSearchActive(false);
    }
  };

  // InputRef
  const clearInput = () => {
    setInputValue("");
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <>
      {searchActive && (
        <>
          <form onSubmit={handleSubmit(onSubmitHandler)} ref={formRef}>
            <div className="input-group">
              <div className="suggest-search p-0" style={boxShadowStyle}>
                <div className="row">
                  <div className="col flex-grow-1 align-self-center align-self-lg-start">
                    <div className="input-group">
                      <input
                        ref={inputRef}
                        type="search"
                        className="form-control py-2"
                        placeholder="ค้นหาสินค้าที่ต้องการที่นี่...."
                        autoComplete="off"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        autoFocus
                      />
                      {inputValue && (
                        <a className="clear-button" onClick={clearInput}>
                          <i className="icon icon-clear-search" />
                        </a>
                      )}

                      <div className="d-none d-lg-block ">
                        <button className="btn btn-red">
                          <i className="icon icon-search" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <a
                    className="col-1 d-block d-lg-none"
                    onClick={handleClosePopup}
                  >
                    <i className="icon icon-close" />
                  </a>
                </div>

                {dropdown && (
                  <>
                    <table className="table mb-0">
                      <tbody>
                        {isLoadingSearch ? (
                          <Loader />
                        ) : (
                          <>
                            <hr className="mb-1" />
                            <tr>
                              <td>
                                <p>
                                  <small>BRAND</small>
                                </p>
                                {dataSearch &&
                                  dataSearch.map((data) => (
                                    <>
                                      {data.brandList.length === 0 ? (
                                        <div className="w-100">
                                          <p className="text-center my-3 text-secondary">
                                            ไม่พบข้อมูลแบรนด์
                                          </p>
                                        </div>
                                      ) : (
                                        <>
                                          {data.brandList.map(
                                            (brandArray, brandArrayIndex) => (
                                              <div key={brandArrayIndex}>
                                                {Array.isArray(brandArray) &&
                                                  brandArray.map(
                                                    (brand, brandIndex) => (
                                                      <Link
                                                        href={`/${brand.url_path}`}
                                                        key={brandIndex}
                                                      >
                                                        <a>
                                                          <div className="row my-1">
                                                            <div className="col-4 col-sm-3">
                                                              {brand.image ? (
                                                                <img
                                                                  className="brand w-auto d-block mx-auto"
                                                                  src={
                                                                    brand.image
                                                                  }
                                                                  alt={`brand-image-${brandIndex}`}
                                                                />
                                                              ) : (
                                                                <img
                                                                  className="brand w-auto d-block mx-auto"
                                                                  src={
                                                                    "/assets/images/img_placeholder.jpg"
                                                                  }
                                                                  alt={`brand-image-${brandIndex}`}
                                                                />
                                                              )}
                                                            </div>
                                                            <div className="col-8 col-sm-9">
                                                              <p className="my-2">
                                                                {brand.name}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </a>
                                                      </Link>
                                                    )
                                                  )}
                                              </div>
                                            )
                                          )}
                                          <Link href="/brands">
                                            <a className="btn btn-light w-100 mt-2">
                                              ดูเพิ่มเติม
                                            </a>
                                          </Link>
                                        </>
                                      )}
                                    </>
                                  ))}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">
                                <p>
                                  <small>PRODUCT</small>
                                </p>
                                {dataSearch &&
                                  dataSearch.map((data) => (
                                    <>
                                      {data.productList.length === 0 ? (
                                        <div className="w-100">
                                          <p className="text-center my-3 text-secondary">
                                            ไม่พบข้อมูลสินค้า
                                          </p>
                                        </div>
                                      ) : (
                                        <>
                                          {data.productList
                                            .slice(0, 5)
                                            .map((product, index) => (
                                              <div key={index}>
                                                <Link
                                                  href={`/products/${product.url_key}`}
                                                >
                                                  <a>
                                                    <div className="row my-1">
                                                      <div className="col-4 col-sm-3">
                                                        <img
                                                          style={{
                                                            height: "65px",
                                                          }}
                                                          className="w-auto d-block mx-auto"
                                                          src={
                                                            product.image
                                                              ? product.image
                                                              : PRODUCT_NOT_FOUND_IMG
                                                          }
                                                          alt={`product-img-${index}`}
                                                        />
                                                      </div>
                                                      <div className="col-8 col-sm-9">
                                                        <p className="p-name my-2">
                                                          {product.name}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </a>
                                                </Link>
                                              </div>
                                            ))}
                                          <button
                                            className="btn btn-light w-100 mt-2"
                                            type="submit"
                                          >
                                            ดูเพิ่มเติม
                                          </button>
                                        </>
                                      )}
                                    </>
                                  ))}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default SuggestSearch;
