import {
  useCartProducts,
  getProductDetail,
  getCartId,
} from "@api/customer/cart";
import { useEffect, useState, useRef } from "react";
import { ProductDetailShort } from "@interfaces/dto/product-detail.dto";
import { numberToMoneyString } from "@utils/numbers.util";
import Loader from "@components/atoms/Loader";
import Link from "next/link";
import { CART_ROUTE_PATH } from "@const/route-paths.const";
import NoitemCart from "./NoitemCart";
import { isWebview, clickCartButtonNative } from "@utils/webview.util";

const Minicart = () => {
  const [isFirstLoaded, setIsFirstLoaded] = useState<boolean>(false);
  const [productsMapping, setProductsMapping] = useState<ProductDetailShort[]>(
    []
  );
  const { data, isLoading, error, mutate } = useCartProducts();

  const [currentQuote, setCurrentQuote] = useState<number>();
  const [newQuote, setNewQuote] = useState<number>();

  const fetchCartId = async () => await getCartId();
  fetchCartId().then((resp) => {
    setNewQuote(resp.data);
  });

  useEffect(() => {
    const fetchCartId = async () => await getCartId();

    fetchCartId().then((response) => setCurrentQuote(Number(response.data)));
  }, []);
  // console.log("current", currentQuote);
  // console.log("newQuote", newQuote);

  useEffect(() => {
    document.addEventListener("click", handleClickoutside, true);
    if (data && data.items && data.items.length > 0) {
      setProductsMapping([]);
      data.items.forEach(async (item) => {
        const product = await getProductDetail(item.item_id.toString());
        if (product) {
          setProductsMapping((prev) => [...prev, product]);
        }
      });
      setIsFirstLoaded(true);
    }
  }, [data, isFirstLoaded]);

  // Modal
  const [showMinicart, setShowMinicart] = useState(false);
  const cartRef = useRef<HTMLDivElement>(null);
  const handleClickoutside = (e: any) => {
    if (!cartRef.current?.contains(e.target)) {
      setShowMinicart(false);
    }
    if (e.target.className === "btn btn-red") {
      setShowMinicart(false);
    }
    if (e.target.className === "product-link") {
      setShowMinicart(false);
    }
  };

  // console.log(newQuote, currentQuote);
  // console.log(data);

  return (
    <div className="minicart-wrapper" ref={cartRef}>
      <div className="d-block d-lg-none">
        <Link href={CART_ROUTE_PATH}>
          <a
            className="d-flex minicart-mobile position-relative"
            onClick={(e) => {
              if (isWebview()) {
                e.preventDefault();
                clickCartButtonNative();
              }
            }}
          >
            <i className="icon icon-cart" />
            <span className="minicart-qty">{data ? data.items_qty : 0}</span>
          </a>
        </Link>
      </div>
      <div className="d-none d-lg-block">
        <a className="d-flex" onClick={() => setShowMinicart(!showMinicart)}>
          <i className="icon icon-cart" />
          <p className="d-none d-md-flex flex-column">
            <span>รถเข็นสินค้า</span>
            {currentQuote && (
              <span className="fw-bold">{data?.items_qty} ชิ้น</span>
            )}
          </p>
        </a>

        {showMinicart && (
          <div className="minicart-content">
            {!isFirstLoaded &&
            (isLoading ||
              (data?.items && productsMapping.length !== data.items.length)) ? (
              <Loader />
            ) : (
              <table className="table mt-4 mb-0">
                <tbody>
                  {data && data?.items && data?.items.length > 0 ? (
                    <>
                      {data.items.map((item, idx) => {
                        const mapping = productsMapping.find(
                          (mapping) => mapping.item_id === item.item_id
                        );
                        const options:
                          | { value: string; label: string }[]
                          | undefined = JSON.parse(item.options);
                        return (
                          <tr key={idx}>
                            <td className="text-center">
                              <Link href={`/products/${mapping?.url_key}`}>
                                <a className="product-link">
                                  <img
                                    src={mapping?.image}
                                    alt={item.name}
                                    width={70}
                                  />
                                </a>
                              </Link>
                            </td>
                            <td>
                              <p className="product-name">
                                <Link href={`/products/${mapping?.url_key}`}>
                                  <a className="product-link">{item.name}</a>
                                </Link>
                              </p>
                              {options && options.length > 0 && (
                                <small className="d-block mt-1">
                                  {options[0].label} : {options[0].value}
                                </small>
                              )}
                              <small className="d-block mt-4">
                                จำนวน: {item.qty}
                              </small>
                            </td>
                            <td className="text-end">
                              <b className="d-none d-md-block">
                                {numberToMoneyString(item.price, 0)}.-
                              </b>
                              {item.extension_attributes.regular_price !==
                                item.price && (
                                <>
                                  <small className="text-red text-nowrap">
                                    ประหยัด{" "}
                                    {item.extension_attributes.regular_price -
                                      item.price}
                                    .-
                                  </small>
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <NoitemCart />
                  )}
                </tbody>

                <tfoot>
                  {newQuote === currentQuote &&
                    data?.items &&
                    data?.items.length > 0 && (
                      <tr>
                        <td className="fw-bold">{data?.items_qty} ชิ้น</td>
                        <td colSpan={2} className="text-end fw-bold">
                          <span>ยอดรวมสุทธิ​: </span>
                          <span>
                            {numberToMoneyString(
                              data?.total_segments.find(
                                ({ code }) => code === "subtotal"
                              )!.value,
                              0
                            )}
                            .-
                          </span>
                        </td>
                      </tr>
                    )}
                  <tr>
                    <td colSpan={3} className="text-center">
                      <div className="d-grid gap-2">
                        <Link href={CART_ROUTE_PATH}>
                          <a
                            className="btn btn-red"
                            onClick={(e) => {
                              if (isWebview()) {
                                e.preventDefault();
                                clickCartButtonNative();
                              }
                            }}
                          >
                            ไปที่ตะกร้า
                          </a>
                        </Link>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Minicart;
