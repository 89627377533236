import { useSelector } from "react-redux";
import {
  isAuthenticatedSelector,
  userSelector,
} from "@stores/slices/userSlice";
import {
  CART_ROUTE_TITLE,
  CATEGORIES_ROUTE_TITLE,
  CHECKOUT_ROUTE_TITLE,
  FORGOT_ROUTE_TITLE,
  LOGIN_ROUTE_TITLE,
  MY_ADDRESSES_ROUTE_TITLE,
  MY_TAX_ROUTE_TITLE,
  MY_CHANGEPASS_ROUTE_TITLE,
  MY_ORDERS_ROUTE_TITLE,
  MY_COUPON_ROUTE_TITLE,
  MY_PRIVACY_ROUTER_TITLE,
  MY_PROFILE_ROUTE_TITLE,
  MY_WISHLIST_ROUTE_TITLE,
  MY_REVIEW_ROUTE_TITLE,
  CMS_MAIN_ROUTE_TITLE,
  CMS_RETURN_POLICY_ROUTE_TITLE,
  CMS_WARRANTY_ROUTE_TITLE,
  CMS_FAQ_ROUTE_TITLE,
  CMS_PROTECTION_POLICY_ROUTE_TITLE,
  CMS_TERMS_ROUTE_TITLE,
  CMS_PRIVACY_POLICY_ROUTE_TITLE,
} from "@const/route-paths-title.const";
import {
  ACCOUNT_PRIVACY_SETTING_PATH,
  ADDRESSS_EDIT_ROUTE_PATH,
  ADDRESSS_LIST_ROUTE_PATH,
  ADDRESSS_NEW_ROUTE_PATH,
  TAX_EDIT_ROUTE_PATH,
  TAX_LIST_ROUTE_PATH,
  TAX_NEW_ROUTE_PATH,
  CART_CHECKOUT_ROUTE_PATH,
  CART_ROUTE_PATH,
  CHANGE_PASSWORD_ROUTE_PATH,
  EDIT_PROFILE_ROUTE_PATH,
  FORGOT_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  MY_PROFILE_INFO_ROUTE_PATH,
  MY_PROFILE_ROUTE_PATH,
  M_CATEGORIES_ROUTE_PATH,
  ORDER_HISTORY_CANCEL_PATH,
  ORDER_HISTORY_COMPLETE_PATH,
  ORDER_HISTORY_PATH,
  ORDER_HISTORY_ORDERID_PATH,
  ORDER_HISTORY_PENDING_PATH,
  ORDER_HISTORY_PROCESSING_PATH,
  MY_COUPON_PATH,
  REGISTER_ROUTE_PATH,
  REVIEW_ROUTE_PATH,
  WISHLIST_ROUTE_PATH,
  CMS_SHOPWITH_ROUTE_PATH,
  CMS_RETURN_POLICY_ROUTE_PATH,
  CMS_WARRANTY_ROUTE_PATH,
  CMS_FAQ_ROUTE_PATH,
  CMS_PROTECTION_POLICY_ROUTE_PATH,
  CMS_TERMS_ROUTE_PATH,
  CMS_PRIVACY_POLICY_ROUTE_PATH,
} from "@const/route-paths.const";
import { useRouter } from "next/router";
import React from "react";

type Props = {};

const TopNavigate = (props: Props) => {
  const user = useSelector(userSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const router = useRouter();

  let pageTitle;
  if (
    router.pathname === LOGIN_ROUTE_PATH ||
    router.pathname === REGISTER_ROUTE_PATH
  ) {
    pageTitle = LOGIN_ROUTE_TITLE;
  } else if (router.pathname === FORGOT_ROUTE_PATH) {
    pageTitle = FORGOT_ROUTE_TITLE;
  } else if (router.pathname === CART_ROUTE_PATH) {
    pageTitle = CART_ROUTE_TITLE;
  } else if (
    router.pathname === MY_PROFILE_INFO_ROUTE_PATH ||
    router.pathname === EDIT_PROFILE_ROUTE_PATH
  ) {
    pageTitle = MY_PROFILE_ROUTE_TITLE;
  } else if (
    router.pathname === ORDER_HISTORY_PATH ||
    router.pathname === ORDER_HISTORY_ORDERID_PATH ||
    router.pathname === ORDER_HISTORY_PENDING_PATH ||
    router.pathname === ORDER_HISTORY_PROCESSING_PATH ||
    router.pathname === ORDER_HISTORY_COMPLETE_PATH ||
    router.pathname === ORDER_HISTORY_CANCEL_PATH
  ) {
    pageTitle = MY_ORDERS_ROUTE_TITLE;
  } else if (
    router.pathname === MY_COUPON_PATH 
  ) {
    pageTitle = MY_COUPON_ROUTE_TITLE;
  } else if (
    router.pathname === ADDRESSS_LIST_ROUTE_PATH ||
    router.pathname === ADDRESSS_NEW_ROUTE_PATH ||
    router.pathname === ADDRESSS_EDIT_ROUTE_PATH
  ) {
    pageTitle = MY_ADDRESSES_ROUTE_TITLE;
  } else if (
    router.pathname === TAX_EDIT_ROUTE_PATH ||
    router.pathname === TAX_NEW_ROUTE_PATH ||
    router.pathname === TAX_LIST_ROUTE_PATH
  ) {
    pageTitle = MY_TAX_ROUTE_TITLE;
  } else if (router.pathname === CHANGE_PASSWORD_ROUTE_PATH) {
    pageTitle = MY_CHANGEPASS_ROUTE_TITLE;
  } else if (router.pathname === WISHLIST_ROUTE_PATH) {
    pageTitle = MY_WISHLIST_ROUTE_TITLE;
  } else if (router.pathname === REVIEW_ROUTE_PATH) {
    pageTitle = MY_REVIEW_ROUTE_TITLE;
  } else if (router.pathname === ACCOUNT_PRIVACY_SETTING_PATH) {
    pageTitle = MY_PRIVACY_ROUTER_TITLE;
  } else if (router.pathname === CART_CHECKOUT_ROUTE_PATH) {
    pageTitle = CHECKOUT_ROUTE_TITLE;
  } else if (router.pathname === M_CATEGORIES_ROUTE_PATH) {
    pageTitle = CATEGORIES_ROUTE_TITLE;
  } else if (router.asPath === CMS_SHOPWITH_ROUTE_PATH) {
    pageTitle = CMS_MAIN_ROUTE_TITLE;
  } else if (router.asPath === CMS_RETURN_POLICY_ROUTE_PATH) {
    pageTitle = CMS_RETURN_POLICY_ROUTE_TITLE;
  } else if (router.asPath === CMS_WARRANTY_ROUTE_PATH) {
    pageTitle = CMS_WARRANTY_ROUTE_TITLE;
  } else if (router.asPath === CMS_FAQ_ROUTE_PATH) {
    pageTitle = CMS_FAQ_ROUTE_TITLE;
  } else if (router.asPath === CMS_PROTECTION_POLICY_ROUTE_PATH) {
    pageTitle = CMS_PROTECTION_POLICY_ROUTE_TITLE;
  } else if (router.asPath === CMS_TERMS_ROUTE_PATH) {
    pageTitle = CMS_TERMS_ROUTE_TITLE;
  } else if (router.asPath === CMS_PRIVACY_POLICY_ROUTE_PATH) {
    pageTitle = CMS_PRIVACY_POLICY_ROUTE_TITLE;
  } else {
    pageTitle = `${user.user?.firstname} ${user.user?.lastname}`;
  }
  return (
    <div
      className={`nav-top shadow-sm d-flex p-3 d-lg-none position-relative ${
        router.pathname === MY_PROFILE_ROUTE_PATH
          ? "bg-danger justify-content-start"
          : "bg-white justify-content-center"
      }`}
    >
      <div className="align-self-center w-100">
        {router.pathname !== MY_PROFILE_ROUTE_PATH &&
        router.pathname !== CART_ROUTE_PATH &&
        router.asPath !== CMS_SHOPWITH_ROUTE_PATH &&
        router.pathname !== M_CATEGORIES_ROUTE_PATH && 
        router.pathname !== WISHLIST_ROUTE_PATH ? (
          <a
            onClick={() => router.back()}
            className="position-absolute start-0 ps-3"
          >
            <img src="/assets/images/navi-icon-back.svg" alt="Back"></img>
          </a>
        ) : (
          ""
        )}

        <div className="text-center">
          {router.pathname !== MY_PROFILE_ROUTE_PATH ? (
            <h2>{pageTitle}</h2>
          ) : (
            <div className="text-start text-white d-flex justify-content-between pe-4">
              <div>
                {isAuthenticated && user.user ? (
                  <>
                    <h2>{pageTitle}</h2>
                    <p>{user.user?.email}</p>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {/* <img
                src="/assets/images/icon-setting.svg"
                alt="Profile Setting"
              /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopNavigate;
