export const LOGIN_ROUTE_PATH = "/customer/account/login";
export const REGISTER_ROUTE_PATH = "/customer/account/create";
export const MY_PROFILE_ROUTE_PATH = "/customer/account";
export const MY_PROFILE_INFO_ROUTE_PATH = "/customer/account/profile";
export const EDIT_PROFILE_ROUTE_PATH = "/customer/account/edit/profile";
export const CHANGE_PASSWORD_ROUTE_PATH = "/customer/account/edit/changepass";
export const WISHLIST_ROUTE_PATH = "/customer/account/wishlist";
export const REVIEW_ROUTE_PATH = "/customer/account/review";
export const ADDRESSS_LIST_ROUTE_PATH = "/customer/address/list";
export const ADDRESSS_NEW_ROUTE_PATH = "/customer/address/new";
export const ADDRESSS_EDIT_ROUTE_PATH = "/customer/address/edit";
export const TAX_LIST_ROUTE_PATH = "/customer/tax/list";
export const TAX_NEW_ROUTE_PATH = "/customer/tax/new";
export const TAX_EDIT_ROUTE_PATH = "/customer/tax/edit";
export const ACCOUNT_PRIVACY_SETTING_PATH = "/customer/account/privacy";
export const ORDER_HISTORY_PATH = "/customer/sales/order/history";
export const ORDER_HISTORY_ORDERID_PATH = "/customer/sales/order/history/[orderId]";
export const ORDER_HISTORY_PENDING_PATH = "/customer/sales/order/history?status=pending";
export const ORDER_HISTORY_PROCESSING_PATH = "/customer/sales/order/history?status=processing";
export const ORDER_HISTORY_COMPLETE_PATH = "/customer/sales/order/history?status=complete";
export const ORDER_HISTORY_CANCEL_PATH = "/customer/sales/order/history?status=canceled";
export const MY_COUPON_PATH = "/customer/coupon";
export const CART_PRODUCTS_ROUTE_PATH = "/customer/cart/products";
export const CART_CHECKOUT_ROUTE_PATH = "/customer/cart/checkout";
export const CART_CALLBACK_ROUTE_PATH = "/customer/cart/checkout/callback";
export const CART_CALLBACK_CC_ROUTE_PATH = "/customer/cart/checkout/callback-cc";
export const CART_CHECKOUT_SUCCESS_ROUTE_PATH = "/checkout/onepage/success";
export const CART_CHECKOUT_SUCCESS_ORDERID_ROUTE_PATH = "/checkout/onepage/success/[orderId]";
export const FORGOT_ROUTE_PATH = "/customer/account/forget-password"
export const PRODUCT_LIST_PATH = "/[...categories]"
export const PRODUCT_VIEW_PATH = "/products/[pkey]"
export const NOT_FOUND_ROUTE_PATH = "/404"
export const BRANDS_ROUTE_PATH = "/brands/[name]"
export const FLASHSALE_ROUTE_PATH = "/flashsale"
export const M_CATEGORIES_ROUTE_PATH = "/m/categories"
export const M_TERMS_ROUTE_PATH = "/m/terms-and-conditions";
export const SEARCH_ROUTE_PATH = "/search"
export const HOT_BRAND_ROUTE_PATH = "/brands"
export const NEW_ARRIVAL_ROUTE_PATH = "/new-arrival"


// CMS PAGE
export const CMS_ROUTE_PATH = "/topvalue/[cmskey]"
export const CMS_PRIVACY_POLICY_ROUTE_PATH = "/topvalue/privacy-policy";
export const CMS_PROTECTION_POLICY_ROUTE_PATH = "/topvalue/protection-policy";
export const CMS_TERMS_ROUTE_PATH = "/topvalue/terms-and-conditions";
export const CMS_ABOUTUS_ROUTE_PATH = "/topvalue/about-our-company";
export const CMS_SHOPWITH_ROUTE_PATH = "/topvalue/shop-with-topvalue";
export const CMS_FAQ_ROUTE_PATH = "/topvalue/faq";
export const CMS_WARRANTY_ROUTE_PATH = "/topvalue/warranty";
export const CMS_RETURN_POLICY_ROUTE_PATH = "/topvalue/returns-policy";
export const CMS_PRODCUT_TYPE_ROUTE_PATH = "/topvalue/product-type";
export const CMS_PRIVILEGE_ROUTE_PATH = "/topvalue/privilege";
export const CMS_TOPVALUE1_ROUTE_PATH = "/topvalue/topvalue1";

// Cart
export const CART_ROUTE_PATH = "/customer/cart/products"

// Footer
export const FOLLOW_LINE_ROUTE_PATH = "https://page.line.me/topvalue.com?openQrModal=true";
export const FOLLOW_FB_ROUTE_PATH = "https://www.facebook.com/topvalue";
export const FOLLOW_YT_ROUTE_PATH = "https://www.youtube.com/user/topvalueTH";
export const FOLLOW_IG_ROUTE_PATH = "https://www.instagram.com/topvalue_th/";
export const FOLLOW_TW_ROUTE_PATH = "https://twitter.com/topvalueTH";
export const FOLLOW_TIKTOK_ROUTE_PATH = "https://www.tiktok.com/@topvalue";
export const APP_APPLE_ROUTE_PATH = "https://apps.apple.com/th/app/topvalue-app/id1551086766?l=th";
export const APP_ANDROID_ROUTE_PATH = "https://play.google.com/store/apps/details?id=com.topvalue.android2";
