const isWebViewIOS = () => {
  if (
    window &&
    window.webkit &&
    window.webkit.messageHandlers &&
    typeof window.webkit !== "undefined"
  ) {
    return true;
  }
  return false;
};

export const isWebViewAndroid = () => {
  return navigator && navigator.userAgent && /wv/i.test(navigator.userAgent);
};

export const isIOS = () => {
  return navigator.userAgent.match(/(iPod|iPhone|iPad)/);
};
export const isWebview = () => {
  if (isIOS()) {
    console.log("ios");
    return isWebViewIOS();
  }
  console.log("android");
  return isWebViewAndroid();
};

export const clickHomeButtonNative = () => {
  console.log("clickHomeButtonNative");
  if (isIOS()) {
    if (window.webkit && window.webkit.messageHandlers.clickHomeButton) {
      window.webkit.messageHandlers.clickHomeButton.postMessage("");
    }
  } else {
    if (window.Android) {
      window.Android.clickHomeButton();
    }
  }
};

export const clickCartButtonNative = () => {
  console.log("clickCartButtonNative");
  if (isIOS()) {
    if (window.webkit && window.webkit.messageHandlers.clickCartButton) {
      window.webkit.messageHandlers.clickCartButton.postMessage("");
    }
  } else {
    if (window.Android) {
      window.Android.clickCartButton();
    }
  }
};

export const clickWishListButtonNative = () => {
  console.log("clickWishListButtonNative");
  if (isIOS()) {
    if (window.webkit && window.webkit.messageHandlers.clickWishListButton) {
      window.webkit.messageHandlers.clickWishListButton.postMessage("");
    }
  } else {
    if (window.Android) {
      window.Android.clickWishListButton();
    }
  }
};

export const clickCategoryButtonNative = () => {
  console.log("clickCategoryButtonNative");
  if (isIOS()) {
    if (window.webkit && window.webkit.messageHandlers.clickCategoryButton) {
      window.webkit.messageHandlers.clickCategoryButton.postMessage("");
    }
  } else {
    if (window.Android) {
      window.Android.clickCategoryButton();
    }
  }
};

export const clickAccountButtonNative = () => {
  console.log("clickAccountButtonNative");
  if (isIOS()) {
    if (window.webkit && window.webkit.messageHandlers.clickAccountButton) {
      window.webkit.messageHandlers.clickAccountButton.postMessage("");
    }
  } else {
    if (window.Android) {
      window.Android.clickAccountButton();
    }
  }
};

export const updateCartCountNative = () => {
  console.log("updateCartCountNative");
  if (isIOS()) {
    if (window.webkit && window.webkit.messageHandlers.updateCartCount) {
      window.webkit.messageHandlers.updateCartCount.postMessage("");
    }
  } else {
    if (window.Android) {
      window.Android.updateCartCount();
    }
  }
};

export const updateWishListCountNative = () => {
  console.log("updateWishListCountNative");
  if (isIOS()) {
    if (window.webkit && window.webkit.messageHandlers.updateWishListCount) {
      window.webkit.messageHandlers.updateWishListCount.postMessage("");
    }
  } else {
    if (window.Android) {
      window.Android.updateWishListCount();
    }
  }
};

export const logoutNative = () => {
  console.log("logoutNative");
  if (isIOS()) {
    if (window.webkit && window.webkit.messageHandlers.logout) {
      window.webkit.messageHandlers.logout.postMessage("");
    }
  } else {
    if (window.Android) {
      window.Android.logout();
    }
  }
};

export const clickLoginButtonNative = (message: string) => {
  console.log("clickLoginButtonNative", message);
  if (isIOS()) {
    if (window.webkit && window.webkit.messageHandlers.clickLoginButton) {
      window.webkit.messageHandlers.clickLoginButton.postMessage(message);
    }
  } else {
    if (window.Android) {
      window.Android.clickLoginButton(message);
    }
  }
};

export const receiveTokenLoginNative = (token: string) => {
  console.log("receiveTokenLoginNative", token);
  if (isIOS()) {
    if (window.webkit && window.webkit.messageHandlers.receiveTokenLogin) {
      window.webkit.messageHandlers.receiveTokenLogin.postMessage(token);
    }
  } else {
    if (window.Android) {
      window.Android.receiveTokenLogin(token);
    }
  }
};

export const hideMenuBarNative = () => {
  console.log("hideMenuBarNative");
  if (isIOS()) {
    if (window.webkit && window.webkit.messageHandlers.hideMenuBar) {
      window.webkit.messageHandlers.hideMenuBar.postMessage("");
    }
  } else {
    if (window.Android) {
      window.Android.hideMenuBar();
    }
  }
};

export const showMenuBarNative = () => {
  console.log("showMenuBarNative");
  if (isIOS()) {
    if (window.webkit && window.webkit.messageHandlers.showMenuBar) {
      window.webkit.messageHandlers.showMenuBar.postMessage("");
    }
  } else {
    if (window.Android) {
      window.Android.showMenuBar();
    }
  }
};
