import { composeQueryParams } from "@utils/query.utils";

export const getFilterQuery = ({
  field,
  value,
  cond,
  layer,
}: {
  field: string;
  value: string;
  cond?: string;
  layer: number;
}): string => {
  const queryField = `searchCriteria[filterGroups][${layer}][filters][0][field]=${field}`;
  const queryValue = `searchCriteria[filterGroups][${layer}][filters][0][value]=${value}`;
  const queryCond = cond
    ? `searchCriteria[filterGroups][${layer}][filters][0][conditionType]=${cond}`
    : undefined;
  return composeQueryParams([queryField, queryValue, queryCond]);
};

export const getPageQuery = ({
  pageSize,
  currentPage,
  sortBy,
  soryDirection,
}: {
  pageSize: number;
  currentPage: number;
  sortBy?: string;
  soryDirection?: string;
}): string => {
  const queryPageSize = `searchCriteria[pageSize]=${pageSize}`;
  const queryCurrentPage = `searchCriteria[currentPage]=${currentPage}`;
  const querySortBy = sortBy
    ? `searchCriteria[sortOrders][0][field]=${sortBy}`
    : undefined;
  const querySortDirection = soryDirection
    ? `searchCriteria[sortOrders][0][direction]=${soryDirection}`
    : undefined;
  return composeQueryParams([
    queryPageSize,
    queryCurrentPage,
    querySortBy,
    querySortDirection,
  ]);
};

export const getSortingQuery = ({field, layer}: {field: string ,layer: number}): string => {
  const queryField = `searchCriteria[filterGroups][${layer}][filters][1][field]=${field}`;
  return composeQueryParams([queryField]);
}

export const getSortOrdersQuery = ({field, direction, layer}: {field: string, direction: string ,layer: number}): string => {
  const queryField = `searchCriteria[sortOrders][${layer}][field]=${field}`;
  const queryDirection = `searchCriteria[sortOrders][${layer}][direction]=${direction}`;
  return composeQueryParams([queryField,queryDirection]);
}