import { ACCESS_TOKEN_STORAGE_KEY } from "@const/storage-keys.const";
import { AccessToken } from "@interfaces/dto/access-token.dto";

export const getAccessTokenStorage = (): AccessToken => {
  const res =
    typeof window !== "undefined"
      ? window.localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY)
      : undefined;
  return res && res !== "" && res !== "null" && res !== "undefined"
    ? res
    : undefined;
};

export const setAccessTokenStorage = (accessToken: AccessToken) => {
  if (accessToken) {
    window.localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
  }
};

export const removeAccessTokenStorage = () => {
  window.localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
};
