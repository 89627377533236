import { fetcher } from "@api/_base";
import {
  BannerImages,
  ImageResponse,
  MenuResponse,
  SubCategoryL3,
  VerticalMenu,
} from "@interfaces/dto/home-category.dto";
import useSWR from "swr";

export const useHomeCategories = (): MenuResponse => {
  const { data, error, mutate } = useSWR(`/nextapi/homemenu`, fetcher);

  let verticalMenues: VerticalMenu[] | undefined = undefined;
  let horizontalMenues: SubCategoryL3[] | undefined = undefined;

  if (data && data.length > 0) {
    if (data[0].menu_vertical) {
      verticalMenues = [];
      data[0].menu_vertical.forEach((item: any) => {
        verticalMenues?.push(Object.values(item)[0] as VerticalMenu);
      });
    }
    if (data[0].menu_horizontal) {
      horizontalMenues = data[0].menu_horizontal;
    }
  }

  return {
    data: { menu_vertical: verticalMenues, menu_horizontal: horizontalMenues },
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export const useBannerImages = (): ImageResponse => {
  const { data, error, mutate } = useSWR(`/nextapi/banner-sections/1`, fetcher);

  return {
    data: data && data.length > 0 ? data[0] : undefined,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};
