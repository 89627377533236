import TagManager, { TagManagerArgs } from 'react-gtm-module';

const tagManagerArgs: TagManagerArgs = {
  gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_ID as string,
};

// export const initGtm = (): void => {
//   TagManager.initialize(tagManagerArgs);
// };

export const initializeGTM = () => {
    TagManager.initialize(tagManagerArgs);
};

// export const sendEvent = (eventName: string, dataLayerVariables: { [key: string]: any }): void => {
//   TagManager.dataLayer({
//     event: eventName,
//     ...dataLayerVariables,
//   });
// };

// export const logPageView = (url: string) => {
//     TagManager.dataLayer({
//       event: 'pageView',
//       virtualUrl: url,
//     });
//   };
  
//   export const logCustomEvent = (eventName: string, eventData: any) => {
//     TagManager.dataLayer({
//       event: eventName,
//       ...eventData,
//     });
//   };