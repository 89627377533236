import {
  CMS_ABOUTUS_ROUTE_PATH,
  CMS_FAQ_ROUTE_PATH,
  CMS_PRIVACY_POLICY_ROUTE_PATH,
  CMS_PROTECTION_POLICY_ROUTE_PATH,
  CMS_TERMS_ROUTE_PATH,
  CMS_RETURN_POLICY_ROUTE_PATH,
  CMS_SHOPWITH_ROUTE_PATH,
  CMS_WARRANTY_ROUTE_PATH,
  FOLLOW_LINE_ROUTE_PATH,
  FOLLOW_FB_ROUTE_PATH,
  FOLLOW_YT_ROUTE_PATH,
  FOLLOW_IG_ROUTE_PATH,
  FOLLOW_TW_ROUTE_PATH,
  CART_CHECKOUT_ROUTE_PATH,
  APP_APPLE_ROUTE_PATH,
  APP_ANDROID_ROUTE_PATH,
} from "@const/route-paths.const";
import Link from "next/link";
import { useRouter } from "next/router";
import { addSubscriber } from "@api/customer/profile";
import { useState } from "react";
import { commonToast, errorToast } from "@utils/toast";
import { SubscriberDto } from "@interfaces/dto/user-data.dto";

const Footer = () => {
  const router = useRouter();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const inputReview: SubscriberDto = {
    subscriber_email: email,
  };

  const handleSubscribe = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { isOk, message } = await addSubscriber(inputReview);
      if (isOk) {
        setIsLoading(false);
        commonToast({ isOk, message });
      } else {
        errorToast(message);
        setIsLoading(false);
      }
    } catch (error) {
      errorToast("เกิดข้อผิดพลาดในการสมัครสมาชิก");
      setIsLoading(false);
    }
  };

  return (
    <>
      <footer>
        <div className="d-block d-lg-none"></div>
        <div className="container-fluid d-none d-lg-block">
          <div className="footer-bg bg-white">
            <div className="row">
              <div className="col col-7 col-md-4 d-none d-sm-block">
                <h4 className="footer-title">ติดต่อเรา</h4>
                <ul className="footer-nav">
                  <li>
                    <a href="#">Call Center 1277</a>
                  </li>
                  <li>
                    <a href="#">โทร : 02-0266224</a>
                  </li>
                  <li>
                    <a href="#">E-mail : cs@topvalue.com</a>
                  </li>
                  <li>
                    <a href="#">เวลาทำการ วันจันทร์ - วันเสาร์</a>
                  </li>
                  <li>
                    <a href="#">เวลา 08.00 - 17.00</a>
                  </li>
                </ul>
              </div>
              <div className="col col-5 col-md-4 d-none d-sm-block">
                <h4 className="footer-title">เกี่ยวกับ Topvalue</h4>
                <ul className="footer-nav">
                  <li>
                    <Link href={CMS_ABOUTUS_ROUTE_PATH}>
                      <a>เกี่ยวกับเรา</a>
                    </Link>
                  </li>
                  {/* <li>
                  <a href="#">ขายสินค้ากับเรา</a>
                </li>
                <li>
                  <a href="#">ร่วมงานกับเรา</a>
                </li> */}
                  <li>
                    <Link href={CMS_PRIVACY_POLICY_ROUTE_PATH}>
                      <a>นโยบายความเป็นส่วนตัว</a>
                    </Link>
                  </li>
                  <li>
                    <Link href={CMS_PROTECTION_POLICY_ROUTE_PATH}>
                      <a>นโยบายการคุ้มครองผู้ซื้อ</a>
                    </Link>
                  </li>
                  <li>
                    <Link href={CMS_TERMS_ROUTE_PATH}>
                      <a>ข้อตกลงและเงื่อนไข</a>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col col-7 col-md-4 d-none d-sm-block">
                <h4 className="footer-title">ศูนย์ช่วยเหลือ</h4>
                <ul className="footer-nav">
                  <li>
                    <Link href={CMS_SHOPWITH_ROUTE_PATH}>
                      <a>คู่มือนักช้อป</a>
                    </Link>
                  </li>
                  <li>
                    <Link href={CMS_FAQ_ROUTE_PATH}>
                      <a>คำถามที่พบบ่อย</a>
                    </Link>
                  </li>
                  <li>
                    <Link href={CMS_RETURN_POLICY_ROUTE_PATH}>
                      <a>วิธีเปลี่ยนสินค้า/คืนสินค้า</a>
                    </Link>
                  </li>
                  {/* <li>
                  <a href="#">ร้องเรียนการบริการ</a>
                </li>
                <li>
                  <a href="#">สั่งซื้อสินค้าจำนวนมาก</a>
                </li> */}
                  <li>
                    <Link href={CMS_WARRANTY_ROUTE_PATH}>
                      <a>การรับประกันสินค้า</a>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-4 col-social mt-3 mt-md-0 text-center text-md-start">
                <h4 className="footer-title">เกี่ยวกับ Topvalue</h4>
                <div className="social-icon">
                  <a
                    href={FOLLOW_LINE_ROUTE_PATH}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon icon-line me-2 me-sm-1" />
                  </a>
                  <a
                    href={FOLLOW_FB_ROUTE_PATH}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon icon-facebook me-2 me-sm-1" />
                  </a>
                  <a
                    href={FOLLOW_YT_ROUTE_PATH}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon icon-youtube me-2 me-sm-1" />
                  </a>
                  <br className="d-none d-sm-block" />
                  <a
                    href={FOLLOW_IG_ROUTE_PATH}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon icon-instagram me-2 me-sm-1" />
                  </a>
                  <a
                    href={FOLLOW_TW_ROUTE_PATH}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon icon-twitter me-2 me-sm-1" />
                  </a>
                </div>
              </div>
              <div className="col col-12 col-md-5 mt-3 mt-md-0 d-none d-sm-block">
                <h4 className="footer-title">รับส่วนลดและสิทธิพิเศษก่อนใคร</h4>
                <div className="email-box">
                  <form onSubmit={handleSubscribe}>
                    <div className="input-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="กรุณากรอกอีเมล"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <button
                        className="btn btn-red rounded-2 mx-1"
                        type="submit"
                      >
                        {isLoading ? "• • •" : "สมัคร"}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="d-flex mt-3 d-none d-sm-flex">
                  <div className="download-app">
                    <a
                      href={APP_APPLE_ROUTE_PATH}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/assets/images/app-store.png"
                        className="mb-2"
                        alt="App Store"
                      />
                    </a>
                    <a
                      href={APP_ANDROID_ROUTE_PATH}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/assets/images/google-play.png"
                        className="mb-2"
                        alt="Google Play"
                      />
                    </a>
                  </div>
                  <div className="qrcode">
                    <img src="/assets/images/qrcode.png" alt="QR Code" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-md-4 d-none d-sm-block">
              <div className="col col-7 payment-method mt-4 mt-lg-0">
                <h4 className="footer-title">วิธีการชำระเงิน</h4>
                <div className="logo">
                  <img src="/assets/images/visa-logo.png" alt="Visa" />
                  <img
                    className="mx-1"
                    src="/assets/images/mastercard-logo.png"
                    alt="Mastercard"
                  />
                  <img
                    className="mx-1"
                    src="/assets/images/jcb-logo.png"
                    alt="Jcb"
                  />
                  <br className="d-md-none" />
                  <img
                    className="mx-1"
                    src="/assets/images/cash-on-delivery-logo.png"
                    alt="COD"
                  />
                  <img
                    className="mx-1"
                    src="/assets/images/bank-online-logo.png"
                    alt="Online Banking"
                  />
                  <img
                    className="mx-1"
                    src="/assets/images/pay-by-installments-logo.png"
                    alt="Installment"
                  />
                  <br className="d-md-none" />
                  <img
                    src="/assets/images/rabbit-linepay-logo.png"
                    className="mx-1 rabbit-linepay-logo"
                    alt="Rabbit linepay"
                  />
                </div>
              </div>
              <div className="col col-5 express mt-4 mt-lg-0 d-none d-sm-block">
                <h4 className="footer-title">บริการจัดส่ง</h4>
                <div className="logo">
                  <img
                    src="/assets/images/icon-topvalue-express.png"
                    alt="Topvalue Express"
                  />
                  <img src="/assets/images/icon-thaipost.png" alt="Thaipost" />
                  <img src="/assets/images/icon-kerry.png" alt="Kerry" />
                </div>
              </div>
              <div className="col col-12 mt-4 mt-lg-0 d-none d-sm-block">
                <h4 className="footer-title">ได้รับการตรวจสอบ</h4>
                <div className="logo">
                  <img src="/assets/images/icon-digicertssl.png" alt="SSL" />
                  <img src="/assets/images/icon-ocpb.png" alt="OCPB" />
                  <img src="/assets/images/icon-dbd.png" alt="DBD" />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-black p-3 p-md-3 p-lg-4">
            <p className="copyright text-center">
              COPYRIGHT © ALL RIGHT RESERVED TOPVALUE CORPORATE LTD.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
